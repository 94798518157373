import { TextField } from "@mui/material";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { factories } from "@triplydb/data-factory";
import { getPrefixAndLabel } from "../../../../components/Prefixed";
import { useDatasetPrefixes } from "../../../../helpers/hooks/useDatasetPrefixes";
import { FormValues, Property } from "../Types";
import { Editor } from "./Editor";

const factory = factories.compliant;

const TextFieldEditor: Editor = {
  Component: ({ name, propertyModel }) => {
    const { control } = useFormContext<FormValues>();
    const prefixes = useDatasetPrefixes();
    return (
      <Controller
        name={name}
        control={control}
        rules={{
          validate: (value) => {
            if (value && value.nodeKind !== "Literal") return;
            try {
              if (value?.value && value?.datatype) {
                const str = value.value.trim();
                factory.literal(str, factory.namedNode(value.datatype));
              }
            } catch (e) {
              if (value) {
                const { prefix, label } = getPrefixAndLabel(prefixes, value.datatype);
                return `Not a valid ${prefix ? `${prefix}:${label}` : label}.`;
              }
            }
          },
        }}
        render={({ field: { onChange, value, ref, ...rest }, fieldState: { error } }) => {
          return (
            <TextField
              {...rest}
              inputRef={ref}
              onChange={(e) => {
                onChange({ nodeKind: "Literal", value: e.target.value, datatype: propertyModel.datatype } as Property);
              }}
              required={propertyModel.required}
              value={value?.value || ""}
              error={!!error}
              helperText={error?.message}
              multiline
            />
          );
        }}
      />
    );
  },
  getScore: ({ nodeKind, datatype }) => {
    if (
      nodeKind === "Literal" &&
      // datatype !== "http://www.w3.org/1999/02/22-rdf-syntax-ns#langString" &&
      datatype !== "http://www.w3.org/2001/XMLSchema#boolean"
    ) {
      return 10;
    }
    return 0;
  },
};

export default TextFieldEditor;
