import { IconButton } from "@mui/material";
import * as React from "react";
import { v4 as uuid } from "uuid";
import { factories } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize.js";
import { Dialog, FontAwesomeIcon } from "#components/index.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import { useDatasetPrefixes } from "#helpers/hooks/useDatasetPrefixes.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import Group from "./Forms/Group";

const factory = factories.compliant;

interface Props {
  onGroupAdded: (group: { id: string; label: string; order?: number }) => void;
}

const AddGroup: React.FC<Props> = ({ onGroupAdded }) => {
  const [open, setOpen] = React.useState(false);
  const currentDs = useCurrentDataset()!;

  const datasetPath = `${currentDs.owner.accountName}/${currentDs.name}`;
  const updateUrl = useConstructUrlToApi()({
    pathname: `/datasets/${currentDs.owner.accountName}/${currentDs.name}/update`,
    fromBrowser: true,
  });
  const consoleUrl = useConstructConsoleUrl()();
  const prefixes = useDatasetPrefixes();
  const baseIri = prefixes.find((prefix) => prefix.prefixLabel === "shp")?.iri || `${consoleUrl}/${datasetPath}/shp/`;
  const groupIri = factory.namedNode(`${baseIri}propertyGroup/gp-${uuid()}`);
  const dispatch = useDispatch();
  return (
    <>
      <IconButton
        color="primary"
        aria-label="Create new property-group"
        title="Create new property-group"
        onClick={() => setOpen(true)}
        size="small"
      >
        <FontAwesomeIcon icon="plus" />
      </IconButton>
      <Dialog
        open={open}
        title="Create new property-group"
        closeButton
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <div className="px-5 pb-5">
          <Group
            onSubmit={async (newGroup) => {
              const query = `
              prefix sh: <http://www.w3.org/ns/shacl#>
              prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
              insert {
                ?id a sh:PropertyGroup;
                    sh:order ?order;
                    rdfs:label ?label.
              } where {
                values (?id ?label) {
                  (${termToString(groupIri)} ${termToString(factory.literal(newGroup.label))})
                }
                optional {
                  values ?order {
                    ${newGroup.order ? termToString(factory.literal(newGroup.order, factory.namedNode("http://www.w3.org/2001/XMLSchema#decimal"))) : ""}
                  }
                }
              }

            `;
              const body = new FormData();
              body.set("update", query);

              await fetch(updateUrl, {
                credentials: "same-origin",
                method: "POST",
                body: body,
              });

              onGroupAdded({
                id: groupIri.id,
                label: newGroup.label,
                order: newGroup.order,
              });

              setOpen(false);
            }}
          />
        </div>
      </Dialog>
    </>
  );
};

export default AddGroup;
