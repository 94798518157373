import * as React from "react";
import { asyncConnect } from "redux-connect";
import { Meta } from "#components/index.ts";
import { IComponentProps } from "#containers/index.ts";
import { getDatasetsWithRunningJobs } from "#reducers/admin.ts";
import { GlobalState } from "#reducers/index.ts";
import useAcl from "../../../helpers/hooks/useAcl";
import { useQueryJobsEnabled } from "../../../helpers/hooks/useQueryJobsEnabled";
import ImportJobs from "./ImportJobs";
import QueryJobs from "./QueryJobs";

const AdminJobs: React.FC<IComponentProps> = ({ location }) => {
  const acl = useAcl();
  const queryJobsEnabled = useQueryJobsEnabled();
  return (
    <>
      <Meta currentPath={location.pathname} title="Jobs - Admin settings" />
      <div className="flex column g-7">
        {acl.check({ action: "readJobsOnInstance" }).granted && <ImportJobs />}
        {queryJobsEnabled && acl.check({ action: "manageQueryJobs" }).granted && <QueryJobs />}
      </div>
    </>
  );
};

export default asyncConnect<GlobalState>([
  {
    promise: ({ store: { dispatch } }) => {
      return dispatch<any>(getDatasetsWithRunningJobs());
    },
  },
])(AdminJobs);
