import * as React from "react";
import * as ReduxForm from "redux-form";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import { FormField, Label, MuiSwitchRedux } from "#components/index.ts";
import { useAuthenticatedUser } from "#reducers/auth.ts";

namespace SessionsSettingsForm {
  export interface FormData {
    oneActiveSessionOnly: boolean;
  }
}

interface Props extends Partial<ReduxForm.InjectedFormProps<SessionsSettingsForm.FormData>> {}

const SessionsSettingsComponent: React.FC<Props> = ({ handleSubmit, submitting, submitSucceeded, pristine }) => {
  const authenticatedUser = useAuthenticatedUser();

  if (!authenticatedUser) throw new Error("Expected AuthenticatedUser to be defined");

  return (
    <div>
      <form method="POST" onSubmit={handleSubmit}>
        <FormField
          labelWidth={300}
          label="Session management"
          className="mt-6 mb-5 center mr-3"
          helperText="Allow only one active session for users"
        >
          <ReduxForm.Field name="oneActiveSessionOnly" suppressHydrationWarning component={MuiSwitchRedux} />
        </FormField>
        <div className="form-group mt-5">
          <LoadingButton
            type="submit"
            color="secondary"
            disabled={pristine}
            onClick={handleSubmit}
            loading={submitting}
          >
            Save
          </LoadingButton>
        </div>
      </form>
      {submitSucceeded && pristine && (
        <div className="mt-4">
          <Label success message="You have successfully updated the session settings." />
        </div>
      )}
    </div>
  );
};

const SessionsSettingsForm = ReduxForm.reduxForm<SessionsSettingsForm.FormData, Props>({
  form: "sessionSettings",
  enableReinitialize: true,
})(SessionsSettingsComponent as any);

export default SessionsSettingsForm;
