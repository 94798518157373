import { Alert } from "@mui/material";
import { set } from "lodash-es";
import * as React from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import useCurrentSearch from "#helpers/hooks/useCurrentSearch.ts";
import { stringifyQuery } from "#helpers/utils.ts";
import { DashViewer, DashViewerProps } from ".";

const UriViewerComponent: React.FC<DashViewerProps> = ({ property }) => {
  const location = useLocation();
  const search = useCurrentSearch();

  if (property.nodeKind !== "IRI") return <Alert severity="error">Wrongly configured URI viewer</Alert>;

  set(search, "resource", property.value);
  return (
    <Link
      to={{
        pathname: location.pathname,
        search: stringifyQuery(search),
      }}
    >
      {property.value}
    </Link>
  );
};

const UriViewer: DashViewer = {
  component: UriViewerComponent,
  getScore({ nodeKind }) {
    if (nodeKind === "IRI") return 1;
    return 0;
  },
};

export default UriViewer;
