import { Alert, DialogTitle, IconButton, Tab, Tabs, Typography } from "@mui/material";
import getClassName from "classnames";
import * as connectedReactRouter from "connected-react-router";
import { clone, mapValues, pullAt } from "lodash-es";
import * as React from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { arrayMove, SortableContainer, SortableElement, SortableHandle, SortEndHandler } from "react-sortable-hoc";
import { Models } from "@triply/utils";
import { parsers, serialize } from "@triplydb/sparql-ast";
import { injectVariablesInPlace } from "@triplydb/sparql-ast/inject.js";
import * as Forms from "#components/Forms/index.ts";
import { Button, Dialog, FontAwesomeIcon, FontAwesomeRoundIcon, Markdown } from "#components/index.ts";
import { VisualizationConfig, VisualizationLabel } from "#components/Sparql/Results/index.js";
import useCopyToClipboard from "#helpers/hooks/useCopyToClipboard.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { stringifyQuery } from "#helpers/utils.ts";
import { useContextualEditor } from "../../components/Sparql/Editor/EditorContext";
import QueryVariables, { FormValues as QueryVariableFormValues } from "./forms/QueryVariables.tsx";
import QueryVar, { Props as QueryVarProps } from "./QueryVar.tsx";
import { useSavedQuery } from "./SavedQueryContext.tsx";
import * as styles from "./style.scss";

export interface LocationState {
  addVariableModalOpen?: boolean;
  editingVariable?: Models.VariableConfig | undefined;
  preserveScrollPosition?: boolean;
  codeSnippetModalShown?: boolean;
}

function variableConfigToForm(queryConfig: Models.VariableConfig): QueryVariableFormValues {
  const { termType, ...otherProps } = queryConfig;
  return { ...otherProps, variableType: getVariableType(queryConfig) };
}

function formToVariableConfig(formData: QueryVariableFormValues): Models.VariableConfig {
  // Unregistering fields won't cause them to remove themselves from the state and therefore the formData, so we need to do some extra administration
  if (formData.required) formData.defaultValue = undefined;
  if (formData.variableType === "NamedNode") {
    return {
      name: formData.name,
      termType: formData.variableType,
      defaultValue: formData.defaultValue,
      required: formData.required,
      allowedValues: formData.allowedValues,
    };
  } else {
    let queryVar: Models.VariableConfig = {
      name: formData.name,
      termType: "Literal",
      defaultValue: formData.defaultValue,
      required: formData.required,
      allowedValues: formData.allowedValues,
    };
    if (formData.variableType === "LanguageStringLiteral") queryVar.language = formData.language || "";
    if (formData.variableType === "TypedLiteral") queryVar.datatype = formData.datatype;
    return queryVar;
  }
}

export function getVariableType(variableConfig: Models.VariableConfig): QueryVariableFormValues["variableType"] {
  if (variableConfig.termType === "NamedNode") {
    return "NamedNode";
  } else {
    if (variableConfig.language) {
      return "LanguageStringLiteral";
    } else if (variableConfig.datatype) {
      return "TypedLiteral";
    } else {
      return "StringLiteral";
    }
  }
}

const getApiUrl = (
  queryLink: string,
  currentVersion: string | undefined,
  testValues: { [key: string]: string | undefined },
  variables: Models.VariableConfig[] | undefined,
) => {
  if (currentVersion) queryLink += "/" + currentVersion;
  queryLink += "/run";

  const variableString = variables?.length
    ? "?" +
      new URLSearchParams({
        ...(mapValues(testValues, (v) => v || "") as { [key: string]: string }),
      }).toString()
    : "";

  return queryLink + variableString;
};

const DragHandle = SortableHandle(() => (
  <div className={styles.queryVarDragHandle}>
    <FontAwesomeIcon className={getClassName()} icon="bars" />
  </div>
));

const SortableItem = SortableElement<QueryVarProps>((props: QueryVarProps) => (
  <div className={getClassName("my-2", styles.sortableQueryVarWrapper)}>
    <DragHandle />
    <QueryVar {...props} />
  </div>
));

const SortableQueryVars = SortableContainer<{ children: any }>((props: { children: any }) => {
  return <div className="mt-3 mb-4">{props.children}</div>;
});

const SnippetDialog: React.FC<{
  open: boolean;
  handleCloseModal: () => void;
  warning?: string | false;
  apiUrl: string;
}> = ({ open, handleCloseModal, warning, apiUrl }) => {
  const { ref: copyFrameRef, copyToClipboard } = useCopyToClipboard();
  const [currentTab, setCurrentTab] = React.useState(0);

  // Adding pageSize argument to Python and R to deal with issue: https://issues.triply.cc/issues/6693
  const snippetUrl = new URL(apiUrl);
  snippetUrl.searchParams.set("pageSize", "10000");

  const pythonSnippet = `import requests, json
url = "${snippetUrl}"
response = requests.get(url)
data = response.json()
`;

  const rSnippet = `library(httr) # for getting data
library(jsonlite) # for working with JSON
URL = "${snippetUrl}"
data <- GET(URL)
data <- fromJSON(content(data, as = 'text', encoding = "UTF-8"))`;

  const snippets = [pythonSnippet, rSnippet];

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleCloseModal}>
      <DialogTitle>Code snippets</DialogTitle>
      <div className="mx-5">
        {!!warning && (
          <Alert className="mb-3" severity="warning">
            {warning}
          </Alert>
        )}
        <Tabs
          classes={{ root: styles.tabs }}
          value={currentTab}
          indicatorColor="primary"
          onChange={(_, tab) => setCurrentTab(tab)}
        >
          <Tab label="Python" id="simple-tab-0" aria-controls="simple-tab-panel-0" classes={{ root: styles.tab }} />
          <Tab label="R" id="simple-tab-1" aria-controls="simple-tab-panel-1" classes={{ root: styles.tab }} />
        </Tabs>
        <Typography
          component="div"
          role="tabpanel"
          hidden={currentTab !== 0}
          id="simple-tabpanel-0"
          aria-labelledby="simple-tab-0"
        >
          <Markdown>{`\`\`\`python\n${snippets[0]}`}</Markdown>
        </Typography>
        <Typography
          component="div"
          role="tabpanel"
          hidden={currentTab !== 1}
          id="simple-tabpanel-1"
          aria-labelledby="simple-tab-1"
        >
          <Markdown>{`\`\`\`r\n${snippets[1]}`}</Markdown>
        </Typography>

        {/* Need a ref inside the Dialog here, as Dialogs catch events */}
        <div ref={copyFrameRef}></div>
      </div>
      <div className="m-5">
        <Button onClick={() => copyToClipboard(snippets[currentTab])} startIcon={<FontAwesomeIcon icon="clipboard" />}>
          Copy to clipboard
        </Button>
        <Button onClick={handleCloseModal} className="ml-3" variant="text">
          Close
        </Button>
      </div>
    </Dialog>
  );
};

interface Props {
  onVariableDefinitionsChange: (vars?: Models.VariableConfig[]) => void; //Setting vars to undefined resets the draft state
  testValues: { [varName: string]: string | undefined };
  onTestValuesChange: (testValues: { [varName: string]: string | undefined }) => void;
  isDraft: boolean;
  variableDefinitions: Models.VariableConfig[] | undefined;
  currentVersion: string | undefined;
  visualization: VisualizationLabel | undefined;
  visualizationConfig: VisualizationConfig;
}

const QueryVars: React.FC<Props> = ({
  onVariableDefinitionsChange,
  testValues,
  onTestValuesChange,
  isDraft,
  variableDefinitions,
  currentVersion,
  visualization,
  visualizationConfig,
}) => {
  const dispatch = useDispatch();
  const { state: locationState, search } = useLocation<LocationState | undefined>();
  const { ref: copyQueryRunLinkRef, copyToClipboard } = useCopyToClipboard();
  const { query, datasetPath } = useSavedQuery();
  const { getQueryString, variablesInQuery, dirty } = useContextualEditor();

  const onSortEnd: SortEndHandler = React.useCallback(
    ({ oldIndex, newIndex }) => {
      if (!variableDefinitions) return;
      onVariableDefinitionsChange(arrayMove(variableDefinitions, oldIndex, newIndex));
    },
    [variableDefinitions, onVariableDefinitionsChange],
  );
  const handleRemove = React.useCallback(
    (variable: Models.VariableConfig) => {
      if (!variableDefinitions) return;
      const i = variableDefinitions.findIndex((v) => v.name === variable.name);
      if (i < 0) return;
      //cloning, as we might otherwise modify immutable objects
      const clonedVars = clone(variableDefinitions);
      pullAt(clonedVars, [i]);
      onVariableDefinitionsChange(clonedVars);
    },
    [onVariableDefinitionsChange, variableDefinitions],
  );
  const handleCloseModal = React.useCallback(() => {
    dispatch(connectedReactRouter.goBack());
  }, [dispatch]);
  const handleFormSubmit = React.useCallback(
    (formData: QueryVariableFormValues) => {
      const queryVar = formToVariableConfig(formData);
      //cloning arrays, to avoid manipulating an immutable array
      const variables = clone(variableDefinitions) || [];

      if (locationState?.editingVariable) {
        const varName = locationState.editingVariable.name;
        //Using the previous variable name here. That way we're robusy against
        //variable name changes
        const i = variables.findIndex((v) => v.name === varName);

        variables[i] = queryVar;
      } else {
        //we're adding a var
        variables.push(queryVar);
      }
      onVariableDefinitionsChange(variables);
      handleCloseModal();
    },
    [handleCloseModal, locationState?.editingVariable, onVariableDefinitionsChange, variableDefinitions],
  );

  const getInjectedQueryString = React.useCallback(() => {
    try {
      const queryString = getQueryString();
      return (
        (!!queryString &&
          !!variableDefinitions?.length &&
          serialize(
            injectVariablesInPlace(parsers.lenient(queryString, { baseIri: "https://triplydb.com/" }), {
              declarations: variableDefinitions,
              values: testValues,
            }),
          )) ||
        ""
      );
    } catch {
      return "";
    }
  }, [testValues, variableDefinitions, getQueryString]);
  const openAddVariableDialog = React.useCallback(
    () =>
      dispatch(
        connectedReactRouter.push<LocationState>({
          state: { addVariableModalOpen: true, preserveScrollPosition: true },
          search: search,
        }),
      ),
    [dispatch, search],
  );
  const apiUrl = getApiUrl(query.link, currentVersion, testValues, variableDefinitions);
  const serviceName = query.serviceConfig.service?.name;
  const handleCopyToClipboard = React.useCallback(() => {
    copyToClipboard(apiUrl);
  }, [apiUrl, copyToClipboard]);
  return (
    <div className={styles.queryVars}>
      <div className="flex center mb-3">
        <h4>API</h4>
        <div ref={copyQueryRunLinkRef}></div>
        <div className={styles.apiRunLinkWrapper}>
          <p className={styles.codeSnippetLink}>GET: {apiUrl}</p>
          <IconButton
            title="Copy query run link"
            aria-label="Copy query run link"
            onClick={handleCopyToClipboard}
            size="small"
            className="ml-2"
          >
            <FontAwesomeIcon icon="copy" />
          </IconButton>
          <div className="grow" />
        </div>
        <div className={styles.codeSnippet}>
          {isDraft ? (
            <div title="Code snippets are available after saving the query">
              <FontAwesomeIcon style={{ fontSize: "120%", cursor: "not-allowed" }} icon="code" />
            </div>
          ) : (
            <Link
              title="Code snippets for Python and R"
              to={{ state: { codeSnippetModalShown: true, preserveScrollPosition: true } }}
            >
              <FontAwesomeIcon style={{ fontSize: "120%" }} icon="code" />
            </Link>
          )}
        </div>
        <SnippetDialog
          open={!!locationState?.codeSnippetModalShown}
          handleCloseModal={handleCloseModal}
          warning={
            query.accessLevel !== "public" && "This query is not public and cannot be accessed from external sources"
          }
          apiUrl={apiUrl}
        />
      </div>

      <div className={getClassName(styles.apiUrlWrapper)}>
        <h4>Variables</h4>
        <FontAwesomeRoundIcon
          title="Add variable"
          aria-label="Add variable"
          icon="plus"
          className={styles.addVariable}
          onClick={openAddVariableDialog}
        />
      </div>

      {variableDefinitions && variableDefinitions.length > 0 && (
        <SortableQueryVars onSortEnd={onSortEnd} useDragHandle>
          {variableDefinitions?.map((variableDefinition, index) => (
            <SortableItem
              key={variableDefinition.name}
              index={index}
              onRemove={handleRemove}
              variableDefinition={variableDefinition}
              getQueryString={getQueryString}
              datasetPath={datasetPath}
              testValue={testValues[variableDefinition.name]}
              onTestValueChange={(val: string) => {
                onTestValuesChange({ ...testValues, [variableDefinition.name]: val });
              }}
              warning={
                variablesInQuery && variablesInQuery?.indexOf(variableDefinition.name) < 0
                  ? "This variable is not present in the query"
                  : undefined
              }
            />
          ))}
        </SortableQueryVars>
      )}

      {variableDefinitions && variableDefinitions.length > 0 && !!datasetPath && !!serviceName && (
        <div className="mt-1">
          <Button
            endIcon={<FontAwesomeIcon icon="external-link" size="xs" className="ml-1" />}
            variant="text"
            size="small"
            onClick={() => {
              window.open(
                `/${datasetPath}/sparql${serviceName === "Speedy" ? "" : `/${serviceName}`}#${stringifyQuery({
                  query: getInjectedQueryString() || getQueryString(),
                  outputFormat: visualization === "LDFrame" ? "Table" : visualization,
                  outputSettings: visualizationConfig as any, // stringifyQuery can deal with nested objects just fine.
                  endpoint: query.service,
                })}`,
                "_blank",
              );
            }}
          >
            View populated query
          </Button>
        </div>
      )}

      <Dialog
        open={!!locationState?.addVariableModalOpen}
        maxWidth="lg"
        fullWidth
        onClose={handleCloseModal}
        title={locationState?.editingVariable ? "Update variable" : "Create a new variable"}
      >
        <QueryVariables
          datasetPath={datasetPath}
          onSubmit={handleFormSubmit}
          variables={variablesInQuery}
          variableDefinitions={variableDefinitions}
          onCancel={handleCloseModal}
          initialValues={
            locationState?.editingVariable ? variableConfigToForm(locationState.editingVariable) : undefined
          }
        />
      </Dialog>
    </div>
  );
};

export default React.memo(QueryVars);
