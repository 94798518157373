import { Button } from "@mui/material";
import * as React from "react";
import { v4 as uuid } from "uuid";
import { factories } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize.js";
import fetch from "#helpers/fetch.ts";
import { useDatasetPrefixes } from "#helpers/hooks/useDatasetPrefixes.ts";
import useRemovePrefixes from "#helpers/hooks/useRemovePrefixes.ts";
import { clearClassDescriptions } from "#reducers/resourceEditorDescriptions.ts";
import { ConfirmationDialog, Dialog, FontAwesomeIcon } from "../../components";
import useConstructConsoleUrl from "../../helpers/hooks/useConstructConsoleUrl";
import useConstructUrlToApi from "../../helpers/hooks/useConstructUrlToApi";
import useDispatch from "../../helpers/hooks/useDispatch";
import { refreshDatasetsInfo, useCurrentDataset } from "../../reducers/datasetManagement";
import { getGraphs } from "../../reducers/graphs";
import PropertyForm from "./Forms/Property";
import { PropertyData } from "./Forms/Types";

const factory = factories.compliant;

const AddProperty: React.FC<{
  classShapeIri: string;
}> = ({ classShapeIri }) => {
  const [open, setOpen] = React.useState(false);
  const [isDirty, setDirty] = React.useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);

  const currentDs = useCurrentDataset()!;
  const datasetPath = `${currentDs.owner.accountName}/${currentDs.name}`;
  const updateUrl = useConstructUrlToApi()({
    pathname: `/datasets/${currentDs.owner.accountName}/${currentDs.name}/update`,
    fromBrowser: true,
  });
  const consoleUrl = useConstructConsoleUrl()();
  const dispatch = useDispatch();
  const removePrefixes = useRemovePrefixes();
  const prefixes = useDatasetPrefixes();
  const baseIri = prefixes.find((prefix) => prefix.prefixLabel === "shp")?.iri || `${consoleUrl}/${datasetPath}/shp/`;

  const onClose = React.useCallback(() => {
    isDirty ? setConfirmationDialogOpen(true) : setOpen(false);
  }, [isDirty]);

  return (
    <>
      <Button color="primary" startIcon={<FontAwesomeIcon icon="plus" />} onClick={() => setOpen(true)} size="small">
        Add property
      </Button>
      <ConfirmationDialog
        open={confirmationDialogOpen}
        onConfirm={() => {
          setConfirmationDialogOpen(false);
          setOpen(false);
        }}
        onClose={() => setConfirmationDialogOpen(false)}
        title="Are sure you want to close this form?"
        actionLabel="Close"
        description="If you close the form now all changes will be lost"
      />

      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth title="Add property" closeButton>
        <div className="px-5 pb-5">
          <PropertyForm
            onDirty={setDirty}
            onSubmit={async (newProperty: PropertyData) => {
              const idIri = factory.namedNode(removePrefixes(newProperty.id));
              const propertyShapeIri = factory.namedNode(`${baseIri}p-${uuid()}`);
              const propertyLabel = newProperty.label?.trim();
              const propertyDescription = newProperty.description?.trim();
              const minCount = newProperty.minCount;
              const maxCount = newProperty.maxCount;
              const defaultValue = newProperty.defaultValue;
              const query = `
              prefix sh: <http://www.w3.org/ns/shacl#>
              prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
              prefix owl: <http://www.w3.org/2002/07/owl#>
              insert {
                ?shapeNode sh:property ?propertyShape.
                ?id a owl:DatatypeProperty.
                ?propertyShape a sh:ProperyShape;
                              sh:path ?id;
                              sh:name ?label;
                              sh:description ?description;
                              sh:datatype ?datatype;
                              sh:class ?class;
                              sh:minCount ?minCount;
                              sh:maxCount ?maxCount;
                              sh:minLength 1;
                              sh:defaultValue ?defaultValue;
                              sh:order ?order;
                              sh:group ?group.
              } where {
                bind(${termToString(factory.namedNode(classShapeIri))} as ?shapeNode)
                bind(${termToString(propertyShapeIri)} as ?propertyShape)
                bind(${termToString(idIri)} as ?id)
                optional { values ?datatype {
                    ${newProperty.datatype ? termToString(factory.namedNode(newProperty.datatype)) : ""}
                  }
                }
                optional {
                  values ?class {
                    ${newProperty.class ? termToString(factory.namedNode(newProperty.class)) : ""}
                  }
                }
                optional { 
                  values ?label {
                    ${propertyLabel ? termToString(factory.literal(propertyLabel)) : ""}
                  }
                }
                optional {
                  values ?description {
                    ${propertyDescription ? termToString(factory.literal(propertyDescription)) : ""}
                  }
                }
                optional {
                  values ?maxCount {
                    ${maxCount || ""}
                  }
                }
                optional {
                  values ?minCount {
                    ${minCount || ""}
                  }
                }
                optional {
                  values ?order {
                    ${newProperty.order ? termToString(factory.literal(newProperty.order, factory.namedNode("http://www.w3.org/2001/XMLSchema#decimal"))) : ""}
                  }
                }
                optional {
                  values ?group {
                    ${newProperty.group ? termToString(factory.namedNode(newProperty.group.id)) : ""}
                  }
                }
                optional {
                  values ?defaultValue {
                    ${defaultValue ? termToString(factory.literal(defaultValue, newProperty.datatype)) : ""}
                  }
                }
              }`;

              const body = new FormData();
              body.set("update", query);

              await fetch(updateUrl, {
                credentials: "same-origin",
                method: "POST",
                body: body,
              });

              await dispatch<typeof refreshDatasetsInfo>(
                refreshDatasetsInfo({ accountName: currentDs.owner.accountName, datasetName: currentDs.name }),
              );
              await dispatch<typeof getGraphs>(
                getGraphs({
                  accountName: currentDs.owner.accountName,
                  datasetName: currentDs.name,
                  datasetId: currentDs.id,
                }),
              );
              await dispatch<typeof clearClassDescriptions>(clearClassDescriptions(currentDs.id));

              setOpen(false);
            }}
          />
        </div>
      </Dialog>
    </>
  );
};

export default AddProperty;
