import {
  Alert,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import * as React from "react";
import { data } from "vis-network";
import { factories } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize.js";
import IdeToolTipButton from "#components/IdeTooltipButton/index.tsx";
import useAcl from "#helpers/hooks/useAcl.ts";
import useApplyPrefixes from "#helpers/hooks/useApplyPrefixes.ts";
import useCurrentResource from "#helpers/hooks/useCurrentResource.ts";
import useSparql from "#helpers/hooks/useSparql.ts";
import { useCurrentAccount } from "#reducers/app.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import AddProperty from "./AddProperty";
import AddSparqlBasedConstraint from "./AddSparqlBasedConstraint";
import RemoveProperty from "./RemoveProperty";
import * as styles from "./styles/index.scss";

export const factory = factories.compliant;

const NO_GROUP = "none";

export interface PropertyInfo {
  propertyShape: string;
  propertyShapeLabel?: string;
  path: string;
  propertyShapeInherited: boolean;
  propertyRange?: string;
  propertyRangeLabel?: string;
  propertyShapeDataType?: string;
  propertyShapeDescription?: string;
  propertyShapeDefaultValue?: string;
  propertyMin?: number;
  propertyMax?: number;
  order?: number;
}

const classQuery = (classIri: string) => `
prefix sh: <http://www.w3.org/ns/shacl#>
prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>

select
  ?shapeIri
  ?groups_groupIri
  ?groups_groupName
  ?groups_properties_propertyShape
  ?groups_properties_propertyShapeLabel
  ?groups_properties_path
  ?groups_groupOrder
  ?groups_properties_propertyShapeInherited
  ?groups_properties_propertyRange
  ?groups_properties_propertyRangeLabel
  ?groups_properties_propertyShapeDataType
  ?groups_properties_propertyShapeDescription
  ?groups_properties_propertyShapeDefaultValue
  ?groups_properties_propertyMin
  ?groups_properties_propertyMax
  ?groups_properties_order
where {
  bind(${termToString(factory.namedNode(classIri))} as ?currentClass)
  ?shapeIri sh:targetClass ?currentClass.
  ?currentClass rdfs:subClassOf*/^sh:targetClass ?nodeShape .
  optional {
    ?nodeShape sh:property ?groups_properties_propertyShape .
    ?groups_properties_propertyShape sh:path ?groups_properties_path .
    bind((?nodeShape != ?shapeIri) as ?groups_properties_propertyShapeInherited)
    
    optional {
    ?groups_properties_propertyShape sh:class ?groups_properties_propertyRange
    optional {
      ?groups_properties_propertyRange rdfs:label ?groups_properties_propertyRangeLabel
    }
  }
  optional {
    ?groups_properties_propertyShape sh:datatype ?groups_properties_propertyShapeDataType
  }
  optional {
    ?groups_properties_propertyShape sh:description ?groups_properties_propertyShapeDescription
  }

  optional {
    ?groups_properties_propertyShape sh:name ?groups_properties_propertyShapeLabel_sh.
  }
  optional {
    ?groups_properties_propertyShape sh:defaultValue ?groups_properties_propertyShapeDefaultValue
  }
  optional {
    ?groups_properties_path rdfs:label ?groups_properties_propertyRangeLabel_rdfs
    }
  optional {
    ?groups_properties_propertyShape sh:order ?groups_properties_order .
  }
  bind(coalesce(?groups_properties_propertyShapeLabel_sh,?groups_properties_propertyRangeLabel_rdfs) as ?groups_properties_propertyShapeLabel)
  optional {
    ?groups_properties_propertyShape sh:minCount ?groups_properties_propertyMin.
  }
  optional {
    ?groups_properties_propertyShape sh:maxCount ?groups_properties_propertyMax.
  }
  optional {
    ?groups_properties_propertyShape sh:group ?groupIri .
    ?groupIri rdfs:label ?groups_groupName.
    optional {
      ?groupIri  sh:order ?groups_groupOrder .
    }
  }
  
  bind(coalesce(?groupIri, "${NO_GROUP}") as ?groups_groupIri)
  }
}
order by (!bound(?groups_groupOrder)) coalesce(?groups_groupOrder, -1000000) (!bound(?groups_properties_order)) ?groups_properties_order
`;

export type SparqlBasedConstraint = {
  nodeShape: string;
  inherited: "true" | "false";
  query: string;
  message?: string;
  shSparql: string;
  datatype: undefined;
};

const ClassInfo: React.FC<{}> = ({}) => {
  const currentClass = useCurrentResource();
  const currentAccount = useCurrentAccount();
  const currentDs = useCurrentDataset();
  const acl = useAcl();
  const mayManageDataModel = acl.check({
    action: "manageDataModel",
    context: { roleInOwnerAccount: acl.getRoleInAccount(currentAccount) },
  }).granted;

  const {
    data: classInfo,
    loading,
    error,
  } = useSparql<{
    groups: {
      groupIri: string;
      groupName?: string;
      groupOrder?: number;
      properties: PropertyInfo[];
    }[];
    shapeIri: string;
  }>(classQuery(currentClass), {
    singularizeVariables: { "": true },
  });

  const {
    data: sparqlBasedConstraints,
    error: sparqlBasedConstraintsError,
    loading: loadingConstraints,
  } = useSparql<SparqlBasedConstraint[]>(
    currentClass &&
      `
      prefix owl: <http://www.w3.org/2002/07/owl#>
      prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
      prefix sh: <http://www.w3.org/ns/shacl#>

      select
        ?nodeShape
        ?inherited
        ?query
        ?shSparql
        ?message
      where {
        bind(${termToString(factory.namedNode(currentClass))} as ?currentClass)
        ?currentNodeShape sh:targetClass ?currentClass.
        ?currentClass rdfs:subClassOf*/^sh:targetClass ?nodeShape.

        bind((?nodeShape != ?currentNodeShape) as ?inherited)

        ?nodeShape sh:sparql ?shSparql .
        ?shSparql sh:select ?query .

        optional {
          ?shSparql sh:message ?message
        }

      }

      group by
      ?currentClass
      ?nodeShape
      ?propertyShape
      ?inherited
      ?class
      ?id
      ?query
      ?message
      ?shSparql

      order by
      ?message
    `,
  );

  const applyPrefixes = useApplyPrefixes();

  if (error || sparqlBasedConstraintsError) return <Alert severity="warning">Class info could not be loaded.</Alert>;

  if (loading || loadingConstraints) return <Skeleton />;
  if (!currentClass || !(mayManageDataModel && classInfo?.shapeIri)) return null;

  return (
    <>
      <Typography variant="h6" component="h2">
        Properties
      </Typography>
      <TableContainer className={styles.tableContainer}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Datatype/Range</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Default value</TableCell>
              <TableCell>Min relations</TableCell>
              <TableCell>Max relations</TableCell>
              <TableCell>Order</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {classInfo.groups?.map((group) => {
              return (
                <React.Fragment key={group.groupIri}>
                  {group.groupIri !== NO_GROUP && (
                    <TableRow className={styles.grouped}>
                      <TableCell colSpan={7}>{group.groupName}</TableCell>
                      <TableCell>{group.groupOrder}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  )}
                  {group.properties?.map((property) => {
                    return (
                      <TableRow key={property.propertyShape}>
                        <TableCell className={group.groupIri !== NO_GROUP ? styles.grouped : undefined}></TableCell>
                        <TableCell>{property.propertyShapeLabel || property.propertyShape}</TableCell>
                        <TableCell>
                          {property.propertyShapeDataType
                            ? applyPrefixes(property.propertyShapeDataType)
                            : property.propertyRangeLabel || applyPrefixes(property.propertyRange)}
                        </TableCell>
                        <TableCell>{property.propertyShapeDescription}</TableCell>
                        <TableCell>{property.propertyShapeDefaultValue}</TableCell>
                        <TableCell>{property.propertyMin}</TableCell>
                        <TableCell>{property.propertyMax}</TableCell>
                        <TableCell>{property.order}</TableCell>
                        <TableCell>
                          {property.propertyShapeInherited === false && mayManageDataModel && (
                            <RemoveProperty
                              label={property.propertyShapeLabel || property.propertyShape}
                              subjectIri={property.propertyShape}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div>{mayManageDataModel && classInfo.shapeIri && <AddProperty classShapeIri={classInfo.shapeIri} />}</div>

      <Typography variant="h6" component="h3">
        Constraints
      </Typography>
      <TableContainer className={styles.propertyTableContainer}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Query</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sparqlBasedConstraints?.map((sparqlBasedConstraint) => (
              <TableRow key={sparqlBasedConstraint.shSparql} className={styles.propertyRow}>
                <TableCell title={applyPrefixes(sparqlBasedConstraint.shSparql)}>
                  {sparqlBasedConstraint.message || applyPrefixes(sparqlBasedConstraint.shSparql)}
                </TableCell>
                <TableCell>
                  <IdeToolTipButton queryString={sparqlBasedConstraint.query ?? ""}></IdeToolTipButton>
                </TableCell>
                <TableCell>
                  {sparqlBasedConstraint.inherited === "false" && mayManageDataModel && (
                    <RemoveProperty
                      subjectIri={sparqlBasedConstraint.shSparql}
                      label={sparqlBasedConstraint.message ?? sparqlBasedConstraint.shSparql}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div>
        {mayManageDataModel && classInfo.shapeIri && <AddSparqlBasedConstraint classShapeIri={classInfo.shapeIri} />}
      </div>
    </>
  );
};

export default ClassInfo;
