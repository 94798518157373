import { FormControl, InputLabel, Select } from "@mui/material";
import getClassName from "classnames";
import { upperFirst } from "lodash-es";
import * as React from "react";
import { QueryVariableFieldProps } from "./QueryVariableField.tsx";
import * as styles from "./style.scss";

const VariableSelectField: React.FC<QueryVariableFieldProps> = ({
  testValue,
  onTestValueChange,
  variableDefinition,
  required,
  fieldVariant,
}) => {
  if (!variableDefinition.allowedValues) return null;
  return (
    <FormControl variant={fieldVariant || "outlined"} className={styles.muiOverride} required={required}>
      <InputLabel shrink>{upperFirst(variableDefinition.name)}</InputLabel>
      <Select
        className={getClassName({ [styles.placeholder]: !testValue })}
        label={variableDefinition.name}
        value={testValue || ""}
        displayEmpty
        native
        onChange={(event) => onTestValueChange(event.target.value as string)}
        variant={fieldVariant || "outlined"}
      >
        <option value="">
          {variableDefinition.defaultValue ? `${variableDefinition.defaultValue} (default)` : "(None)"}
        </option>
        {variableDefinition.allowedValues.map((value) => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};
export default VariableSelectField;
