import { Checkbox } from "@mui/material";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormValues, Property } from "../Types";
import { Editor } from "./Editor";

const BooleanEditor: Editor = {
  Component: ({ name, propertyModel }) => {
    const { control } = useFormContext<FormValues>();
    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <Checkbox
              checked={value?.value === "true"}
              onChange={(_, checked) => {
                onChange({
                  nodeKind: "Literal",
                  datatype: propertyModel.datatype,
                  value: checked ? "true" : "false",
                } as Property);
              }}
            />
          );
        }}
      />
    );
  },
  getScore: ({ nodeKind, datatype }) => {
    if (nodeKind === "Literal" && datatype === "http://www.w3.org/2001/XMLSchema#boolean") {
      return 10;
    }
    return 0;
  },
};

export default BooleanEditor;
