import { Alert, Skeleton, Typography } from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";
import { factories } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize.js";
import useApplyPrefixes from "#helpers/hooks/useApplyPrefixes.ts";
import useCurrentResource from "#helpers/hooks/useCurrentResource.ts";
import useSparql from "#helpers/hooks/useSparql.ts";
import { stringifyQuery } from "../../helpers/utils";
import { useCurrentDataset } from "../../reducers/datasetManagement";

const factory = factories.compliant;
const Instances: React.FC<{}> = ({}) => {
  const currentClass = useCurrentResource();
  const currentDs = useCurrentDataset();
  const applyPrefixes = useApplyPrefixes();

  const { data, error, loading } = useSparql<{ instance: string; label?: string }[]>(
    currentClass &&
      `
    prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
    prefix skos: <http://www.w3.org/2004/02/skos/core#>

    select
      ?instance
      (sample(?l) as ?label)
    where {
      bind(${termToString(factory.namedNode(currentClass))} as ?currentClass)
      {
        ?instance a ?currentClass
        optional {
          ?instance rdfs:label|skos:prefLabel ?l
        }
      } union {
        ?subClass rdfs:subClassOf+ ?currentClass .
        ?instance a ?subClass
        optional {
          ?instance rdfs:label|skos:prefLabel ?l
        }
      }
    }
    group by ?instance
    limit 10
  `,
  );

  if (error) return <Alert severity="warning">Instances could not be loaded.</Alert>;

  if (loading) return <Skeleton />;

  if (!currentClass || !data || !data[0]) return null;

  return (
    <div>
      <Typography variant="h6" component="h2">
        Instances
      </Typography>
      {data.map((instance) => {
        return (
          <div key={instance.instance}>
            <Link
              to={{
                pathname: `/${currentDs!.owner.accountName}/${currentDs!.name}/browser`,
                search: stringifyQuery({ resource: instance.instance }),
              }}
              draggable
              onDragStart={(e) => e.dataTransfer.setData("text/plain", instance.instance)}
            >
              {instance.label || applyPrefixes(instance.instance)}
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default Instances;
