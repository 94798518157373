import { Paper } from "@mui/material";
import * as React from "react";
import { useSelector } from "react-redux";
import { SubmissionError } from "redux-form";
import { SessionsSettings } from "#components/Forms/index.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { updateSessionSettings } from "#reducers/config.ts";
import { GlobalState } from "#reducers/index.ts";

interface Props {}

const Sessions: React.FC<Props> = ({}) => {
  const oneActiveSessionOnly = useSelector((state: GlobalState) => state.config.clientConfig?.oneActiveSessionOnly);
  const dispatch = useDispatch();

  const handleSessionSettings = (values: SessionsSettings.FormData) => {
    return dispatch<typeof updateSessionSettings>(updateSessionSettings(values)).then(
      () => {},
      (e: any) => {
        throw new SubmissionError({ _error: e.message });
      },
    );
  };

  return (
    <Paper variant="outlined" className="p-5">
      <h4>Sessions</h4>
      <SessionsSettings
        initialValues={{
          oneActiveSessionOnly: oneActiveSessionOnly ?? false,
        }}
        onSubmit={handleSessionSettings}
      />
    </Paper>
  );
};
export default Sessions;
