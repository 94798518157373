import { set } from "lodash-es";
import * as React from "react";
import { useHistory, useLocation } from "react-router";
import { v4 as uuid } from "uuid";
import { factories } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize.js";
import { Button, ConfirmationDialog, FontAwesomeIcon } from "#components/index.ts";
import fetch from "#helpers/fetch.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useCurrentSearch from "#helpers/hooks/useCurrentSearch.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { parseSearchString, stringifyQuery } from "#helpers/utils.ts";
import { useAuthenticatedUser } from "#reducers/auth.ts";
import { refreshDatasetsInfo, useCurrentDataset } from "#reducers/datasetManagement.ts";
import { getGraphs } from "#reducers/graphs.ts";
import { removeEditorDescription } from "#reducers/resourceEditorDescriptions.ts";
import { MuiColor } from "../Process/processDefinitions";

const factory = factories.compliant;
const RemoveResource: React.FC<{
  color?: MuiColor;
  name: string;
  fromStatus: string;
}> = ({ color, name, fromStatus }) => {
  const [open, setOpen] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const currentDs = useCurrentDataset()!;

  const updateUrl = useConstructUrlToApi()({
    pathname: `/datasets/${currentDs.owner.accountName}/${currentDs.name}/update`,
    fromBrowser: true,
  });
  const dispatch = useDispatch();
  const resource = parseSearchString(useLocation().search).resource as string;
  const search = useCurrentSearch();
  const history = useHistory();
  const consoleUrl = useConstructConsoleUrl()();
  const authorAcc = useAuthenticatedUser();
  const authorUrl = `${consoleUrl}/${authorAcc?.accountName}`;
  const datasetPath = `${currentDs.owner.accountName}/${currentDs.name}`;
  const historyIri = factory.namedNode(`${consoleUrl}/${datasetPath}/history/r-${uuid()}`);
  const removedValue = termToString(factory.namedNode(resource));
  return (
    <>
      <Button
        color={color === "default" ? undefined : color}
        elevation
        onClick={() => setOpen(true)}
        title="Remove this instance from the dataset"
        startIcon={<FontAwesomeIcon icon="trash" />}
        size="small"
      >
        {name}
      </Button>
      <ConfirmationDialog
        open={open}
        loading={saving}
        onClose={() => setOpen(false)}
        actionLabel="Remove instance"
        title="Remove this instance?"
        onConfirm={async () => {
          setSaving(true);

          const query = `
          prefix meta: <https://triplydb.com/Triply/TriplyDB-instance-editor-vocabulary/>
          delete {
            ?removedValue ?p ?o.
            ?x ?y ?removedValue.
          } where {
            bind (${removedValue} as ?removedValue)
            {
              ?removedValue ?p ?o.
            } union {
              ?x ?y ?removedValue.
              filter(?y != meta:product)
            }
          };
          insert {
            ?historyObject a meta:Event;
              meta:actor ?remover;
              meta:time ?removedAt;
              meta:action ?takenAction;
              meta:product ?removedValue;
              meta:fromStatus ?previousStatus.
          } where {
            bind(now() as ?removedAt)
            bind(${removedValue} as ?removedValue)
            bind(${termToString(historyIri)} as ?historyObject)
            bind(${termToString(factory.namedNode(authorUrl))} as ?remover)
            values(?previousStatus ?takenAction) {
              (${fromStatus !== "unknown" ? termToString(factory.literal(fromStatus)) : "undef"}  ${termToString(factory.literal(name))})
            }
          }`;

          const body = new FormData();
          body.set("update", query);

          await fetch(updateUrl, {
            credentials: "same-origin",
            method: "POST",
            body: body,
          });

          await dispatch<typeof refreshDatasetsInfo>(
            refreshDatasetsInfo({ accountName: currentDs.owner.accountName, datasetName: currentDs.name }),
          );
          await dispatch<typeof getGraphs>(
            getGraphs({
              accountName: currentDs.owner.accountName,
              datasetName: currentDs.name,
              datasetId: currentDs.id,
            }),
          );

          await dispatch<typeof removeEditorDescription>(removeEditorDescription(currentDs.id, resource));

          setSaving(false);
          setOpen(false);

          set(search, "resource", undefined);
          history.push({
            search: stringifyQuery(search),
          });
        }}
        description={`Are you sure you want to remove '${resource}' from the dataset?`}
      />
    </>
  );
};

export default RemoveResource;
