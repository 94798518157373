import {
  Alert,
  Autocomplete,
  Skeleton,
  Stack,
  TextField,
  ThemeProvider,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";
import { cloneDeep, merge } from "lodash-es";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router";
import { v4 as uuid } from "uuid";
import { factories } from "@triplydb/data-factory";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import { FormField, Prompt } from "#components/index.ts";
import { getPrefixAndLabel } from "#components/Prefixed/index.tsx";
import useApplyPrefixes from "#helpers/hooks/useApplyPrefixes.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import useCurrentResource from "#helpers/hooks/useCurrentResource.ts";
import { useDatasetPrefixes } from "#helpers/hooks/useDatasetPrefixes.ts";
import useRemovePrefixes from "#helpers/hooks/useRemovePrefixes.ts";
import useSparql from "#helpers/hooks/useSparql.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import AddGroup from "../AddGroup";
import { GroupData } from "./Group";
import { validateIri } from "./helpers";
import localTheme from "./Theme";
import { PropertyData } from "./Types";

const factory = factories.compliant;

const PropertyForm: React.FC<{
  onSubmit: (values: PropertyData) => Promise<void>;
  onDirty: (dirty: boolean) => void;
}> = ({ onSubmit, onDirty }) => {
  const theme = useTheme();

  const currentDs = useCurrentDataset()!;
  const datasetUrl = useConstructConsoleUrl()({ pathname: `/${currentDs.owner.accountName}/${currentDs.name}` });

  const prefixes = useDatasetPrefixes();
  const baseIri = prefixes.find((prefix) => prefix.prefixLabel === "def")?.iri || `${datasetUrl}/def/`;
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors, isDirty },
    setError,
    watch,
    trigger,
    getValues,
    setValue,
  } = useForm<PropertyData>({
    defaultValues: async () => ({
      label: "",
      id: applyPrefixes(`${baseIri}p-${uuid()}`),
      relationType: "object",
    }),
    mode: "onChange",
  });
  const location = useLocation();

  const currentClass = useCurrentResource();

  const applyPrefixes = useApplyPrefixes();
  const removePrefixes = useRemovePrefixes();

  const currentPropertyValue = removePrefixes(watch("id"));

  const relationType = watch("relationType");

  const { data: existingProperties } = useSparql<
    {
      id: string;
      label: string;
      description?: string;
      rangeOptions: { id: string; label?: string; description?: string }[];
    }[]
  >(
    `
    prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
    prefix owl: <http://www.w3.org/2002/07/owl#>

    select
      ?id
      ?rangeOptions_id
      (sample(?label_t) as ?label)
      (sample(?description_t) as ?description)
      (sample(?rangeOptions_label_t) as ?rangeOptions_label)
      (sample(?rangeOptions_description_t) as ?rangeOptions_description)
    where {
      bind(<${currentClass}> as ?class)
      # ?id rdfs:subPropertyOf*/rdfs:domain/^rdfs:subClassOf* ?class.
      ?id rdfs:domain/^rdfs:subClassOf* ?class.
      # filter not exists {
      #   ?id rdfs:subPropertyOf*/rdfs:range/rdfs:subClassOf* rdfs:Literal.
      # }
      ?id a owl:ObjectProperty.
      ?id rdfs:label ?label_t
      optional {
        ?id rdfs:comment ?description_t
      }
      # ?id rdfs:subPropertyOf*/rdfs:range/^rdfs:subClassOf* ?rangeOptions_id.
      ?id rdfs:range/^rdfs:subClassOf* ?rangeOptions_id.
      filter (!regex(str(?rangeOptions_id), "\.well-known/genid"))
      optional {
        ?rangeOptions_id rdfs:label ?rangeOptions_label_t
      }
      optional {
        ?rangeOptions_id rdfs:comment ?rangeOptions_description_t
      }

    }
    group by ?id ?rangeOptions_id
    order by ?label
    `,
    { singularizeVariables: {} },
  );

  const { data: allRangeOptions } = useSparql<{ id: string; label?: string; description?: string }[]>(`
    prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
    prefix owl: <http://www.w3.org/2002/07/owl#>

    select
      ?id
      (sample(?l) as ?label)
      (sample(?d) as ?description)
    where {
      {
        ?id a rdfs:Class
      } union {
        ?id a owl:Class
      } union {
        ?id rdfs:subClassOf|^rdfs:subClassOf []
      }
      filter(?id != rdfs:Literal)
      filter not exists {
        ?id rdfs:subClassOf+ rdfs:Literal
      }
      filter not exists {
        ?id a rdfs:Datatype
      }
      filter (!regex(str(?id), "\.well-known/genid"))

      optional {
        ?id rdfs:label ?l
      }
      optional {
        ?id rdfs:comment ?d
      }
    }
    group by ?id
    order by ?label
    `);
  const { data: datatypes } = useSparql<{ datatype: string; label: string; description?: string }[]>(`
    prefix xsd: <http://www.w3.org/2001/XMLSchema#>
    prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>

    select ?datatype (sample(?label_t) as ?label) (sample(?description_t) as ?description) where {
      {
        values (?datatype ?label_t) {
                (xsd:string 'String')
                }
      } union {
        ?datatype a rdfs:Datatype.
        optional {
          ?datatype rdfs:label ?l.
        }
        bind(coalesce(?l, str(?datatype)) as ?label_t)
        optional {
          ?datatype rdfs:comment ?description_t
        }
      }
    }
    group by ?datatype
    order by ?label
    `);
  const { data: propertyGroups } = useSparql<{ label: string; id: string; order?: number }[]>(`
    prefix sh: <http://www.w3.org/ns/shacl#>
    prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
    select ?label ?id ?order where {
      ?id a sh:PropertyGroup;
          rdfs:label ?label.
      optional {
        ?id sh:order ?order.
      }
    } order by ?order
    `);

  const [addedPropertyGroups, setAddedPropertyGroups] = React.useState<(GroupData & { id: string })[]>([]);

  const currentProperty = currentPropertyValue
    ? existingProperties?.find((p) => p.id === currentPropertyValue)
    : undefined;
  const rangeOptions = currentProperty?.rangeOptions || allRangeOptions || [];

  const submit = async (values: PropertyData) => {
    try {
      await onSubmit(values);
    } catch (e) {
      console.error(e);
      setError("root.serverError", {
        type: "500",
      });
    }
  };

  React.useEffect(() => {
    onDirty(isDirty);
  }, [isDirty, onDirty]);

  if (!existingProperties || !allRangeOptions) {
    return <Skeleton variant="rectangular" width={860} height={175} />;
  }

  return (
    <ThemeProvider theme={merge(cloneDeep(localTheme), theme)}>
      <Prompt
        when={isDirty}
        message={(newState) => {
          //dont want the prompt to show up when only changing the location state
          //Otherwise, drawing the modal would trigger it
          if (location.pathname === newState.pathname) return true;
          return "Your changes have not been saved. Are you sure you want to continue?";
        }}
      />

      <form method="POST" onSubmit={handleSubmit(submit)} className="flex column g-7">
        <FormField label="Label" required>
          <Controller
            name={"label"}
            control={control}
            rules={{
              validate: (value) => {
                if (!value.trim()) return "A label is required.";
              },
            }}
            render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
              <TextField
                {...rest}
                required
                autoFocus
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                value={value || ""}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </FormField>

        <FormField label="Property IRI" required>
          <Controller
            name={"id"}
            control={control}
            rules={{
              validate: validateIri,
            }}
            render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => {
              const currentProperty = existingProperties.find((p) => value === p.id);
              return (
                <Autocomplete
                  fullWidth
                  options={existingProperties}
                  {...rest}
                  onChange={(_e, value) => {
                    if (typeof value === "string") {
                      onChange(removePrefixes(value.trim()));
                    } else if (typeof value === "object" && value && "id" in value) {
                      onChange(value.id);
                    } else {
                      onChange(null);
                    }
                  }}
                  freeSolo
                  value={value || ""}
                  renderInput={(params) => (
                    <TextField
                      {...(params as any)}
                      fullWidth
                      required
                      error={!!error}
                      helperText={
                        error?.message ||
                        (currentProperty && (currentProperty.description || currentProperty.label)) ||
                        (value ? "New property" : "")
                      }
                      onBlur={(e) => {
                        const currentProperty = existingProperties.find(
                          (p) => removePrefixes(e.target.value.trim()) === p.id,
                        );
                        if (currentProperty) {
                          onChange(currentProperty.id);
                        } else {
                          onChange(removePrefixes(e.target.value.trim()));
                        }
                      }}
                    />
                  )}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") return applyPrefixes(option);
                    return applyPrefixes(option.id);
                  }}
                  renderOption={(props, option) => {
                    if (typeof option === "string") return <li>{option}</li>;
                    return (
                      <li {...props}>
                        <div>
                          <span>{option.label}</span>
                          <div>
                            <Typography variant="caption">{applyPrefixes(option.id)}</Typography>
                          </div>
                        </div>
                      </li>
                    );
                  }}
                  isOptionEqualToValue={(option, value) => {
                    if (!option) return false;
                    if (typeof value === "string") {
                      return value === option.id;
                    } else {
                      return option.id === value.id;
                    }
                  }}
                />
              );
            }}
          />
        </FormField>

        <FormField label="Description">
          <Controller
            name={"description"}
            control={control}
            render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
              <TextField
                {...rest}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                minRows={3}
                multiline
                value={value || ""}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </FormField>
        <FormField label="Relation type">
          <Controller
            name="relationType"
            control={control}
            render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => {
              return (
                <ToggleButtonGroup value={value} exclusive onChange={(_e, value) => value && onChange(value)}>
                  <ToggleButton value="object">Object</ToggleButton>
                  <ToggleButton value="datatype">Datatype</ToggleButton>
                </ToggleButtonGroup>
              );
            }}
          />
        </FormField>

        {relationType === "object" && (
          <FormField label="Range" required>
            <Controller
              name={`class`}
              shouldUnregister
              control={control}
              rules={{
                validate: validateIri,
              }}
              render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => {
                return (
                  <Autocomplete
                    {...rest}
                    fullWidth
                    options={rangeOptions}
                    getOptionLabel={(option) => option.label || applyPrefixes(option.id)}
                    onChange={(_e, value) => {
                      onChange(value ? value.id : undefined);
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props}>
                          <div>
                            <span>{option.label}</span>
                            <div>
                              <Typography variant="caption">{applyPrefixes(option.id)}</Typography>
                            </div>
                          </div>
                        </li>
                      );
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return option && option.id === value.id;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...(params as any)}
                        required
                        error={!!error}
                        helperText={error?.message || rangeOptions?.find((r) => r.id === value)?.description}
                        fullWidth
                      />
                    )}
                  />
                );
              }}
            />
          </FormField>
        )}
        {relationType === "datatype" && (
          <FormField label="Datatype" required>
            <Controller
              name={`datatype`}
              shouldUnregister
              control={control}
              rules={{
                validate: validateIri,
              }}
              render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => {
                return (
                  <Autocomplete
                    {...rest}
                    fullWidth
                    options={datatypes || []}
                    getOptionLabel={(option) => applyPrefixes(option.datatype)}
                    onChange={(_e, value) => {
                      onChange(value ? value.datatype : undefined);
                      trigger("defaultValue").catch((reason) => {
                        console.error(reason);
                      });
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props}>
                          <div>
                            <span>{option.label}</span>
                            <div>
                              <Typography variant="caption">{applyPrefixes(option.datatype)}</Typography>
                            </div>
                          </div>
                        </li>
                      );
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return option && option.datatype === value.datatype;
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...(params as any)}
                          required
                          error={!!error}
                          helperText={
                            error?.message ||
                            datatypes?.find((d) => d.datatype === removePrefixes(params.inputProps?.value as any))
                              ?.description
                          }
                          fullWidth
                        />
                      );
                    }}
                  />
                );
              }}
            />
          </FormField>
        )}
        <FormField label="Default value">
          <Controller
            name={"defaultValue"}
            rules={{
              validate:
                relationType === "object"
                  ? validateIri
                  : (value) => {
                      const datatype = getValues("datatype");
                      try {
                        if (value && datatype) {
                          const str = value.trim();
                          factory.literal(str, factory.namedNode(datatype));
                        }
                      } catch (e) {
                        if (datatype) {
                          const { prefix, label } = getPrefixAndLabel(prefixes, datatype);
                          return `Not a valid ${prefix ? `${prefix}:${label}` : label}.`;
                        } else {
                          return "Invalid";
                        }
                      }
                    },
            }}
            control={control}
            render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
              <TextField
                {...rest}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                type="text"
                value={value || ""}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </FormField>

        <FormField label="Min count">
          <Controller
            name={"minCount"}
            control={control}
            render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
              <TextField
                {...rest}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                type="number"
                value={value || ""}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </FormField>

        <FormField label="Max count">
          <Controller
            name={"maxCount"}
            control={control}
            render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
              <TextField
                {...rest}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                type="number"
                value={value || ""}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </FormField>
        <FormField label="Group">
          <Stack direction="row">
            <Controller
              name={"group"}
              control={control}
              render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
                <Autocomplete
                  fullWidth
                  options={[...(propertyGroups || []), ...addedPropertyGroups]}
                  disabled={!propertyGroups || propertyGroups.length === 0}
                  {...rest}
                  onChange={(_e, value) => {
                    if (value) {
                      onChange(value);
                    } else {
                      onChange(null);
                    }
                  }}
                  getOptionKey={(option) => option.id}
                  value={value || null}
                  renderInput={(params) => <TextField {...(params as any)} fullWidth error={!!error} />}
                  getOptionLabel={(option) => {
                    return option.label || applyPrefixes(option.id);
                  }}
                  renderOption={(props, option) => {
                    return (
                      <li {...props}>
                        <div>
                          <span>{option.label}</span>
                          <div>
                            <Typography variant="caption">{applyPrefixes(option.id)}</Typography>
                          </div>
                        </div>
                      </li>
                    );
                  }}
                  isOptionEqualToValue={(option, value) => {
                    if (!option) return false;
                    return option.id === value.id;
                  }}
                />
              )}
            />
            <AddGroup
              onGroupAdded={(newGroup) => {
                setAddedPropertyGroups((groups) => {
                  groups.push(newGroup);
                  return groups;
                });
                setValue("group", newGroup);
              }}
            />
          </Stack>
        </FormField>
        <FormField label="Order">
          <Controller
            name={"order"}
            control={control}
            render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
              <TextField
                {...rest}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                type="number"
                inputMode="decimal"
                value={value || ""}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </FormField>

        <LoadingButton color="secondary" type="submit" disabled={isSubmitting} loading={isSubmitting}>
          Save
        </LoadingButton>

        {errors.root && <Alert severity="error">Something went wrong on the server...</Alert>}
      </form>
    </ThemeProvider>
  );
};

export default PropertyForm;
