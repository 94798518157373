import { padStart } from "lodash-es";

export interface ParsedCalver {
  originalSegments?: [year: string, month: string, versionInMonth: string, patch?: string];
  yearShort: number;
  month: number;
  versionInMonth: number;
  patch?: number;
}
const positiveIntCheck = /^\d+$/;
function asPositiveInt(val?: string) {
  if (typeof val === "string" && positiveIntCheck.test(val)) {
    return Number(val);
  }
  throw new Error(`Expected '${val}' to be a (positive) integer`);
}

function parseAndValidateCalver(calver: string): ParsedCalver {
  return validate(parse(calver));
}

/**
 * Parses a calver string into an object. If the calver is `latest`, then we use a date far in the future
 */
export function parse(calver: string): ParsedCalver {
  // Cater to situations where the version is 'latest-k8' or 'k8-latest' (yes, we use both...)
  if (calver.includes("latest")) calver = "99.01.0";
  const [base, patch] = calver.split("-");

  const [year, month, versionInMonth] = base.split(".");

  try {
    return {
      yearShort: asPositiveInt(year),
      month: asPositiveInt(month),
      versionInMonth: asPositiveInt(versionInMonth),
      patch: patch ? asPositiveInt(patch) : undefined,
      originalSegments: [year, month, versionInMonth, patch],
    };
  } catch (e: any) {
    throw new Error(`Failed to parse calver string ${calver}: ${e.message}`);
  }
}

/**
 * Validate whether a version string is valid. It's valid when:
 * - We have a valid month section (1-12)
 * - zero padding is correctly applied (e.g `01` as month notation instead of `1`)
 */
export function validate(calverOrSemver: ParsedCalver): ParsedCalver {
  if (parsedVersionIsSemver(calverOrSemver)) return calverOrSemver; // Dont validate this, it's semver
  if (calverOrSemver.month < 1 || calverOrSemver.month > 12) {
    throw new Error(`Invalid month number ${calverOrSemver.month}`);
  }
  if (calverOrSemver.originalSegments?.[1].length === 1) {
    throw new Error(
      `Expected calver month-segment to have length of two. Instead, got value ${calverOrSemver.originalSegments[1]}`,
    );
  }
  return calverOrSemver;
}
export function serializeCalver(calver: ParsedCalver): string {
  const base = [calver.yearShort, padStart(String(calver.month), 2, "0"), calver.versionInMonth].join(".");
  if (calver.patch) {
    return base + `-${calver.patch}`;
  }
  return base;
}

function getShortYear(date: Date) {
  return Number(String(date.getFullYear()).substring(2));
}

export function increment(currentVersion: string, opts?: { patch?: boolean; currentDate?: Date }) {
  /**
   * If we want to patch a release, dont modify the calver part. Only increment the patch version
   */
  const parsedCurrentVersion = parseAndValidateCalver(currentVersion);
  if (opts?.patch) {
    if (parsedCurrentVersion.patch !== undefined) {
      parsedCurrentVersion.patch++;
    } else {
      parsedCurrentVersion.patch = 1;
    }
    return serializeCalver(parsedCurrentVersion);
  }
  const date = opts?.currentDate || new Date();
  const currentYearShort = getShortYear(date);
  const currentMonth = date.getMonth() + 1; // dates by default are zero-indexed
  if (currentYearShort !== parsedCurrentVersion.yearShort || currentMonth !== parsedCurrentVersion.month) {
    // This also triggers when we pass a semver (we're assuming the major-version is never 21)
    return serializeCalver({
      yearShort: currentYearShort,
      month: currentMonth,
      versionInMonth: 1,
    });
  } else {
    // Incrementing versionInMonth
    return serializeCalver({
      ...parsedCurrentVersion,
      versionInMonth: parsedCurrentVersion.versionInMonth + 1,
      patch: undefined,
    });
  }
}

export function isSemver(version: string) {
  try {
    return !!parsedVersionIsSemver(parseAndValidateCalver(version));
  } catch {
    return false;
  }
}
/**
 * Check whether the string is valid calver. For simplicity, we consider 'latest' a valid calver as well (defaulting to a date far in the future)
 */
export function isCalver(version: string) {
  try {
    return !parsedVersionIsSemver(parseAndValidateCalver(version));
  } catch (e) {
    return false;
  }
}
function parsedVersionIsSemver(parsedVersion: ParsedCalver): boolean {
  /**
   * Assuming we're not using major-versions of 21 or higher
   */
  return parsedVersion.yearShort < 21;
}
function compare(lhs: string, rhs: string) {
  const lhsIsSemver = parsedVersionIsSemver(parseAndValidateCalver(lhs));
  const rhsIsSemver = parsedVersionIsSemver(parseAndValidateCalver(rhs));
  if (lhsIsSemver && rhsIsSemver)
    throw new Error(`Assuming ${lhs} and ${rhs} are both semver's. We do not support comparing semver alone`);

  if (lhsIsSemver) return -1;
  if (rhsIsSemver) return 1;
  // simple string comparison suffices when we know we're comparing our calver versions
  return lhs.localeCompare(rhs);
}

/**
 * Check whether lhs is greather-than rhs
 * Supported values are calver notation and 'latest'
 */
export function gt(lhs: string, rhs: string) {
  return compare(lhs, rhs) > 0;
}
/**
 * Check whether lhs is greather-than-or-equal to rhs
 * Supported values are calver notation and 'latest'
 */
export function gte(lhs: string, rhs: string) {
  return compare(lhs, rhs) >= 0;
}
/**
 * Check whether lhs is less-than rhs
 * Supported values are calver notation and 'latest'
 */
export function lt(lhs: string, rhs: string) {
  return compare(lhs, rhs) < 0;
}
/**
 * Check whether lhs is less-than-than-or-equal to rhs
 * Supported values are calver notation and 'latest'
 */
export function lte(lhs: string, rhs: string) {
  return compare(lhs, rhs) <= 0;
}

/**
 * Calver has four pieces of information:
 * - year (two digist)
 * - month (two digist)
 * - sprint / versionInMonth (one digit)
 * - patch (up to two digits)
 *
 * Semver, however, only has three. So we decided to combine them as
 * `yy.mm.spp`. For example, `24.08.1-3` will become `24.8.103` and `24.08.0`
 * will be `24.8.0`.
 *
 * See also https://issues.triply.cc/issues/9499
 */
export function toSemver(calver: ParsedCalver) {
  let semverPatch = (calver.patch || 0).toString();
  if (semverPatch.length > 2) throw new Error(`Patch version is too high: ${semverPatch}`);
  if (calver.versionInMonth !== 0) semverPatch = calver.versionInMonth + semverPatch.padStart(2, "0");
  if (semverPatch.length > 3) throw new Error(`Version in month is too high: ${calver.versionInMonth}`);
  return [calver.yearShort, calver.month, semverPatch].join(".");
}
export function toCalver(semver: string): ParsedCalver {
  const parsed = parse(semver);
  let versionInMonth;
  let patch: number | undefined;
  if (parsed.versionInMonth >= 100) {
    if (parsed.patch) throw new Error(`Cannot convert ${semver} to calver`);
    patch = parsed.versionInMonth % 100;
    versionInMonth = (parsed.versionInMonth - patch) / 100;
  } else {
    versionInMonth = parsed.versionInMonth;
    patch = parsed.patch;
  }
  return {
    yearShort: parsed.yearShort,
    month: parsed.month,
    versionInMonth,
    patch,
  };
}
