import { Decoration, hoverTooltip, ViewPlugin } from "@codemirror/view";
import { NoResultRangesFacet } from "../facets/highlightedRanges";

export function getNoResultRangesHighlighter(markClass: string) {
  const markAsUnusedMark = Decoration.mark({ class: markClass });
  const noResultsPlugin = ViewPlugin.define(
    (view) => {
      return {
        noResultRanges: view.state.facet(NoResultRangesFacet).map((el) => {
          return {
            from: view.state.doc.line(el.startLine).from + el.startColumn - 1,
            to: view.state.doc.line(el.endLine).from + el.endColumn,
          };
        }),
        constructor() {},

        update(update) {
          if (update.state.facet(NoResultRangesFacet) !== update.startState.facet(NoResultRangesFacet)) {
            this.noResultRanges = update.state.facet(NoResultRangesFacet).map((el) => {
              return {
                from: update.state.doc.line(el.startLine).from + el.startColumn - 1,
                to: update.state.doc.line(el.endLine).from + el.endColumn,
              };
            });
          } else if (update.docChanged) {
            this.noResultRanges = [];
          }
        },
      };
    },
    {
      decorations: (pluginObject) =>
        Decoration.set(
          pluginObject.noResultRanges.map((node) => markAsUnusedMark.range(node.from, node.to)),
          true,
        ),
    },
  );
  return noResultsPlugin.extension;
}

export const noResultsHover = hoverTooltip((view, pos, side) => {
  const positions = view.state.facet(NoResultRangesFacet);
  const currentLine = view.state.doc.lineAt(pos);
  const currentColumn = pos - currentLine.from;
  const hoveredResult = positions.find((position) => {
    return (
      position.startLine <= currentLine.number &&
      currentLine.number <= position.endLine &&
      (position.startLine === currentLine.number ? position.startColumn - 1 <= currentColumn : true) &&
      (position.endLine === currentLine.number ? position.endColumn >= currentColumn : true)
    );
  });
  if (hoveredResult) {
    return {
      pos: view.state.doc.line(hoveredResult.startLine).from + hoveredResult.startColumn - 1,
      end: view.state.doc.line(hoveredResult.endLine).from + hoveredResult.endColumn,
      above: true,
      create() {
        let dom = document.createElement("div");
        const text = document.createElement("span");
        text.textContent = `Returned 0 results`;
        dom.className = `cm-diagnostic cm-diagnostic-warning`;
        dom.appendChild(text);
        return { dom };
      },
    };
  }
  return null;
});
