import * as React from "react";
import { useSelector } from "react-redux";
import { useCurrentAccount } from "#reducers/app.ts";
import { GlobalState } from "#reducers/index.ts";
import { COMPLEX_PROCESS, EditorInstanceAction, EditorInstanceStatus, SIMPLE_PROCESS } from "./processDefinitions";

interface Props {
  children: React.ReactElement | React.ReactElement[];
}

const ProcessContext = React.createContext<{
  getStatusConfigFor: (statusString?: string) => {
    status: EditorInstanceStatus;
    actions: EditorInstanceAction[];
  };
  getCreateActions: () => EditorInstanceAction[];
}>({
  getStatusConfigFor() {
    throw new Error("Context has not been mounted");
  },
  getCreateActions() {
    throw new Error("Context has not been mounted");
  },
});

export const useEditorProcessContext = () => {
  return React.useContext(ProcessContext);
};

const ProcessContextProvider: React.FC<Props> = ({ children }) => {
  const currentAccount = useCurrentAccount();
  const editorType = useSelector(
    (state: GlobalState) =>
      state.config.clientConfig?.editorProcessConfig?.[currentAccount?.type === "org" ? "organizations" : "users"],
  );
  const config =
    editorType === "none" || !editorType ? undefined : editorType === "complex" ? COMPLEX_PROCESS : SIMPLE_PROCESS;
  const getStatusConfigFor = React.useCallback(
    (statusString?: string | undefined) => {
      const status = !statusString
        ? { name: "unknown" }
        : config?.statuses.find((status) => status.name === statusString) || { name: "unrecognized" };
      return {
        status: status,
        actions: config?.actions.filter((action) => "onState" in action && action.onState.includes(status.name)) || [],
      };
    },
    [config],
  );
  const getCreateActions = React.useCallback(() => {
    if (!config) {
      return SIMPLE_PROCESS.actions.filter((actions) => actions.type === "create");
    }
    return config?.actions.filter((actions) => actions.type === "create") || [];
  }, [config]);
  return (
    <ProcessContext.Provider
      value={{
        getStatusConfigFor: getStatusConfigFor,
        getCreateActions: getCreateActions,
      }}
    >
      {children}
    </ProcessContext.Provider>
  );
};

export default ProcessContextProvider;
