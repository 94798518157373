import { TextField } from "@mui/material";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { FormField, LoadingButton } from "#components/index.ts";

interface Props {
  onSubmit: (data: GroupData) => void;
}

export type GroupData = {
  label: string;
  order?: number;
};

const Group: React.FC<Props> = ({ onSubmit }) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors, isDirty },
  } = useForm<GroupData>();
  return (
    <form className="flex column g-7">
      <FormField label="Label" required>
        <Controller
          name="label"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
            <TextField
              {...rest}
              required
              autoFocus
              onChange={(e) => {
                onChange(e.target.value);
              }}
              value={value || ""}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      </FormField>
      <FormField label="Order">
        <Controller
          name={"order"}
          control={control}
          render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
            <TextField
              {...rest}
              onChange={(e) => {
                onChange(e.target.value);
              }}
              type="number"
              inputMode="decimal"
              value={value || ""}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      </FormField>
      <LoadingButton
        color="secondary"
        type="button"
        disabled={isSubmitting}
        loading={isSubmitting}
        onClick={handleSubmit(onSubmit)}
      >
        Save
      </LoadingButton>
    </form>
  );
};

export default Group;
