import { TabContext, TabPanel } from "@mui/lab";
import { Divider, NoSsr } from "@mui/material";
import getClassName from "classnames";
import { parse as parseHashString } from "qs";
import * as React from "react";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import { Models } from "@triply/utils";
import { Prefix } from "@triply/utils/prefixUtils.js";
import { stringifyQuery } from "@triply/utils-private";
import { TermAutocompleteFunction } from "#components/Sparql/Editor/autocompleters/termAutocomplete.ts";
import { VisualizationConfig, VisualizationLabel } from "#components/Sparql/Results/index.tsx";
import { GlobalState } from "#reducers/index.ts";
import SparqlIDETabMenu from "./SparqlEditorTabs.tsx";
import SparqlQuery from "./SparqlQuery.tsx";
import { queryConfigLinkToIde as savedQueryLinkConfigToIde, useSparqlIDEContext } from "./useSparqlIDEContext.tsx";
import * as styles from "./styles.scss";

interface Props {
  prefixes: Prefix[];
  datasetUrl: string;
  availableServices: {
    name: string;
    id: string;
    endpoint: string;
    type: Models.SparqlQueryServiceType;
  }[];
}
export interface SparqlIDETab {
  name: string;
  query: string;
  visualization: VisualizationLabel;
  visualizationConfig: VisualizationConfig;
}
interface LocationState {
  serviceName: string;
}

const SparqlIDE: React.FC<Props> = ({ prefixes, datasetUrl, availableServices }) => {
  const termPath = datasetUrl + "/terms";
  const { addTab, ideConfig, setSelectedTab } = useSparqlIDEContext();
  const { replace, push, location } = useHistory<LocationState | undefined>();
  const onServiceRoute = useRouteMatch<{ serviceName: string }>("/:user/:dataset/sparql/:serviceName");
  const staticConfig = useSelector((state: GlobalState) => state.config.staticConfig);
  const currentService = onServiceRoute
    ? availableServices.find((service) => service.name === onServiceRoute.params.serviceName) || availableServices[0] // Can't find the name of the service, lets just use the default one
    : availableServices[0]; // Should always be speedy

  // Handling navigation stuff
  React.useEffect(() => {
    try {
      // Saved queries
      if (location.hash) {
        const tabFromHash = parseHashString(location.hash.slice(1));
        if (tabFromHash && addTab) {
          replace({ ...location, hash: "" });
          // Before #9791 we accidentally encoded the output settings twice. This if-check is just here to be sure.
          if (tabFromHash.outputSettings && typeof tabFromHash.outputSettings === "string") {
            try {
              tabFromHash.outputSettings = JSON.parse(tabFromHash.outputSettings);
            } catch {
              tabFromHash.outPutSettings = {};
            }
          }
          addTab(savedQueryLinkConfigToIde(tabFromHash as any, availableServices[0].endpoint, staticConfig));
        }
        // Open with a specific service
      } else if (ideConfig?.queries && location.state?.serviceName) {
        replace({ ...location, state: undefined });
        // First check the currently set tab if it already uses that service
        if (ideConfig.queries[ideConfig.selectedTab].endpointName === location.state?.serviceName) {
          return;
        }
        // Check if we already have a tab with that service
        const tabIdWithRequestedService = Object.entries(ideConfig.queries).find(
          // We don't use ID in this function, but we do need it for setSelectedTab call
          ([_id, config]) => config.endpointName === location.state?.serviceName,
        )?.[0];
        if (tabIdWithRequestedService) {
          setSelectedTab(tabIdWithRequestedService);
        } else {
          // Create a new tab with that service name
          const service = availableServices.find((service) => service.name === location.state?.serviceName);
          if (service) {
            // The service actually exists
            addTab?.({
              endpointName: service.name,
              endpointType: service.type,
            });
          } else {
            // Should show the requested endpoint as not being available
            addTab?.({
              endpointName: location.state.serviceName,
            });
          }
        }
      }
    } catch {
      // Do nothing
    }
  }, [
    location,
    addTab,
    replace,
    currentService.endpoint,
    ideConfig?.queries,
    setSelectedTab,
    availableServices,
    staticConfig,
    ideConfig?.selectedTab,
  ]);

  const searchTerms: TermAutocompleteFunction = React.useCallback(
    async (searchString, position) => {
      return fetch(
        `${termPath}?${stringifyQuery({ q: searchString, ...(position === "all" ? {} : { pos: position }) })}`,
      ).then((res) => res.json());
    },
    [termPath],
  );
  if (!ideConfig) {
    return null;
  }
  const { selectedTab, tabOrder, queries } = ideConfig;
  const { loading } = queries[selectedTab];

  return (
    // We don't have access to localStorage
    <NoSsr>
      <TabContext value={selectedTab}>
        <SparqlIDETabMenu />
        <Divider className={getClassName({ [styles.loading]: loading })} />
        {tabOrder.map((tabId) => {
          return (
            <TabPanel key={tabId} value={tabId} className={styles.editorContainer}>
              <SparqlQuery
                {...ideConfig.queries[tabId]}
                prefixes={prefixes}
                searchTerms={searchTerms}
                tabId={tabId}
                key={tabId}
                availableServices={availableServices}
              />
            </TabPanel>
          );
        })}
      </TabContext>
    </NoSsr>
  );
};

export default SparqlIDE;
