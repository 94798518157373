import { Box, FormControl, FormHelperText, MenuItem, Select, Tooltip } from "@mui/material";
import getClassName from "classnames";
import { capitalize } from "lodash-es";
import * as React from "react";
import { useSelector } from "react-redux";
import * as ReduxForm from "redux-form";
import { Models } from "@triply/utils";
import { SPARQL_SERVICE_TYPES } from "@triply/utils/Constants.js";
import { ServiceType } from "@triply/utils/Models.js";
import { ServiceTypeBadge } from "#components/index.ts";
import { GlobalState } from "../../../reducers";
import * as styles from "./style.scss";

interface Props {
  className?: string;
  servicesMetaOfSelectedDataset: Models.ServiceMetadataSmall[];
}

const ServiceType: React.FC<ReduxForm.WrappedFieldProps & Props> = ({
  input,
  meta,
  className,
  servicesMetaOfSelectedDataset,
}) => {
  const enabledServiceTypes = useSelector((state: GlobalState) =>
    SPARQL_SERVICE_TYPES.filter((type) => (state.config.clientConfig?.enabledServices || []).includes(type)),
  );
  return (
    <Box sx={{ minWidth: 200 }}>
      <FormControl fullWidth error={!!meta.error} className={className}>
        <Select variant="outlined" displayEmpty {...input}>
          <MenuItem value="speedy">
            <div className={getClassName("flex center", styles.serviceTypeValue)}>
              <ServiceTypeBadge type="speedy" size="sm" className="mr-2" />
              <span>Speedy</span>
            </div>
          </MenuItem>
          {enabledServiceTypes.map((serviceType) => (
            <MenuItem
              sx={
                !servicesMetaOfSelectedDataset.filter((s) => s.type === serviceType).length
                  ? { opacity: 0.5 }
                  : undefined
              }
              key={serviceType}
              value={serviceType}
            >
              <div className={getClassName("flex center", styles.serviceTypeValue)}>
                <ServiceTypeBadge type={serviceType} size="sm" className="mr-2" />
                <span>{capitalize(serviceType)}</span>
              </div>
            </MenuItem>
          ))}
        </Select>
        {meta.error && <FormHelperText>{meta.error}</FormHelperText>}
      </FormControl>
    </Box>
  );
};

export default ServiceType;
