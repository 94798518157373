import { TextField } from "@mui/material";
import getClassName from "classnames";
import eachDeep from "deepdash/eachDeep";
import { omit, throttle } from "lodash-es";
import memoizee from "memoizee";
import { stringToTerm } from "rdf-string";
import * as React from "react";
import AutoSuggest, { SuggestionsFetchRequestedParams } from "react-autosuggest";
import { FindTermsQuery } from "@triply/utils/Models.js";
import * as Routes from "@triply/utils/Routes.js";
import { validation } from "@triply/utils-private";
import { parsers, Types } from "@triplydb/sparql-ast";
import { fetchJson } from "#helpers/fetch.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import { QueryVariableFieldProps } from "./QueryVariableField.tsx";
import * as styles from "./style.scss";

const getCompletionDetails = memoizee(
  function (queryString: string | undefined, variableName: string): FindTermsQuery {
    const incomingPredicates: string[] = [];
    const predicateObject: Array<{ predicate: string; object: string }> = [];
    const outgoingPredicates: string[] = [];
    const positions = new Set<"subject" | "predicate" | "object" | "graph">();
    if (queryString) {
      try {
        const query = parsers.lenient(queryString, { baseIri: "https://triplydb.com/" });
        // Find triple patterns that have:
        // - a term as predicate AND
        // - the relevant API variable as object
        // OR
        // - the relevant API variable as subject AND
        // - have a term as predicate
        // OR
        // - the relevant API variable as subject AND
        // - have a term as predicate AND
        // - have a term as object
        eachDeep(query, (node) => {
          if (typeof node !== "object" || node === null) return;
          if ("type" in node && node.type === "graph") {
            const graphPattern = node as Types.GraphPattern;
            if ((graphPattern.name.termType as string) === "Variable" && graphPattern.name.value === variableName)
              positions.add("graph");
          }
          if ("subject" in node && "predicate" in node && "object" in node) {
            const { subject, predicate, object } = node as Types.Triple;
            if (subject.termType === "Variable" && subject.value === variableName) {
              positions.add("subject");
              if ("termType" in predicate && predicate.termType === "NamedNode") {
                outgoingPredicates.push(predicate.value);
                if (object.termType === "Literal" || object.termType === "NamedNode")
                  predicateObject.push({
                    predicate: predicate.value,
                    object: object.value,
                  });
              }
            }
            if ("termType" in predicate && predicate.termType === "Variable" && predicate.value === variableName)
              positions.add("predicate");
            if (object.termType === "Variable" && object.value === variableName) {
              positions.add("object");
              if ("termType" in predicate && predicate.termType === "NamedNode")
                incomingPredicates.push(predicate.value);
            }
          }
        });
      } catch (err) {
        // most likely a parse error
        console.error(err);
        return {};
      }
      // @DECISION  we chose to use the predicate of the first occurrence if a
      //            variable occurs with multiple predicates, because the
      //            completion will be better than when ignoring all of the
      //            predicates, and the risk of confusion will be there anyway
      if (predicateObject.length) return { pos: "subject", ...predicateObject[0] };
      // Although it seems like the `length === 1` and `length` checks below are
      // the same, the order matters: if one of `incomingPredicates` and
      // `outgoingPredicates` has length 1, we can be more precise. If both have
      // `length !== 1`, then we'll use whatever we have available.
      if (incomingPredicates.length === 1) return { pos: "object", predicate: incomingPredicates[0] };
      if (outgoingPredicates.length === 1) return { pos: "subject", predicate: outgoingPredicates[0] };
      if (incomingPredicates.length) return { pos: "object", predicate: incomingPredicates[0] };
      if (outgoingPredicates.length) return { pos: "subject", predicate: outgoingPredicates[0] };

      if (positions.size === 1) {
        for (const pos of positions.values()) {
          return { pos };
        }
      }
    }
    return {};
  },
  // https://github.com/medikoo/memoizee#primitive-mode
  { primitive: true },
);

const getCompletions = memoizee(
  (url: string, query: FindTermsQuery) =>
    fetchJson<Routes.datasets._account._dataset.terms.Get>(url, {
      method: "GET",
      query,
      credentials: "same-origin",
    }).then((response) => {
      if ("json" in response) return response.json;
    }),
  {
    // https://github.com/medikoo/memoizee#writing-custom-cache-id-normalizers
    normalizer: (...args) => JSON.stringify(args),
  },
);

export const InputField: React.FC<QueryVariableFieldProps> = ({
  testValue,
  onTestValueChange,
  variableDefinition,
  datasetPath,
  getQueryString,
  fieldVariant,
}) => {
  const [suggestions, setSuggestions] = React.useState<string[]>();
  const [touched, setTouched] = React.useState(false);
  const constructUrlToApi = useConstructUrlToApi();

  const termsPath = constructUrlToApi({
    pathname: `/datasets/${datasetPath}/terms`,
  });

  const onSuggestionsFetchRequested = React.useMemo(
    () =>
      throttle(async (data: SuggestionsFetchRequestedParams) => {
        if (!datasetPath) return;
        try {
          const completionDetails = getCompletionDetails(getQueryString(), variableDefinition.name);
          const terms = await getCompletions(termsPath, {
            ...completionDetails,
            q: variableDefinition.termType === "Literal" ? `"${data.value}` : data.value,
            termType: variableDefinition.termType,
            dataType:
              variableDefinition.termType === "Literal"
                ? !variableDefinition.datatype && !variableDefinition.language
                  ? "http://www.w3.org/2001/XMLSchema#string"
                  : variableDefinition.datatype
                : undefined,
            languageTag: variableDefinition.termType === "Literal" ? variableDefinition.language : undefined,
          });
          if (!terms) return;
          setSuggestions(terms.map((suggestedString) => stringToTerm(suggestedString).value));
        } catch (e) {
          console.error(e);
        }
      }, 500),
    [datasetPath, getQueryString, variableDefinition, termsPath],
  );

  const required = !!variableDefinition.required && !testValue && touched;

  const suggestionValues =
    (testValue && suggestions?.filter((s) => s.lastIndexOf(testValue, 0) === 0)) || suggestions || [];

  const validateValue = React.useMemo(
    () => validation.toStringValidator(validation.getQueryVarValidations(variableDefinition)),
    [variableDefinition],
  );

  const errorValue = validateValue(testValue);

  return (
    <AutoSuggest
      renderInputComponent={(inputProps) => (
        <TextField
          {...(omit(inputProps, "aria-autocomplete", "aria-controls") as any)}
          inputProps={{
            "aria-autocomplete": inputProps["aria-autocomplete"],
            "aria-controls": inputProps["aria-controls"],
          }}
          label={variableDefinition.name}
          variant={fieldVariant || "outlined"}
          size="small"
          className={getClassName(inputProps.className, styles.muiOverride)}
          InputLabelProps={{ shrink: true }}
          required={variableDefinition.required}
          fullWidth
          error={required || !!errorValue}
          helperText={(required && "A value is required") || errorValue}
        />
      )}
      onSuggestionSelected={(event, suggestion) => {
        event.preventDefault();
        onTestValueChange(suggestion.suggestionValue);
      }}
      suggestions={suggestionValues}
      getSuggestionValue={(s) => s}
      shouldRenderSuggestions={() => true}
      renderSuggestion={(s) => <div>{s}</div>}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={() => setSuggestions(undefined)}
      inputProps={{
        type: "search",
        value: testValue || "",
        placeholder: (required && "A value is required") || variableDefinition.defaultValue,
        onChange: (_e, data) => {
          if (data.method === "type" || data.method === "enter") {
            onTestValueChange(data.newValue);
          }
        },
        onBlur: () => setTouched(true),
      }}
    />
  );
};
