import { IconButton } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { asyncConnect } from "redux-connect";
import { AccountMetadata, ErrorPage, FontAwesomeIcon, Label, MuiTextField } from "#components/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import useCopyToClipboard from "#helpers/hooks/useCopyToClipboard.ts";
import { accountIsCurrentAccount, getAccountInfo, getCurrentAccount } from "#reducers/app.ts";
import { GlobalState } from "#reducers/index.ts";
import * as styles from "./styles.scss";

const TriplyETL: React.FC = () => {
  const { ref: copyFrameRef, copyToClipboard } = useCopyToClipboard();
  const etlLicenseKey = useSelector((state: GlobalState) => state.config.staticConfig?.etlLicenseKey);
  const { currentAccount } = useSelector((state: GlobalState) => ({ currentAccount: getCurrentAccount(state) }));
  const [showKey, setShowKey] = React.useState<boolean>(false);
  const location = useLocation();
  const acl = useAcl();

  if (!currentAccount) {
    return <ErrorPage statusCode={404} />;
  }
  const roleInAccount = acl.getRoleInAccount(currentAccount);
  if (!acl.check({ action: "editAccountMetadata", context: { roleInAccount: roleInAccount } }).granted) {
    return <ErrorPage statusCode={401} />;
  }
  if (!etlLicenseKey) {
    return <ErrorPage statusCode={404} message={"No ETL has been configured"} />;
  }

  return (
    <div className="constrainWidth">
      <AccountMetadata
        currentAccount={currentAccount}
        currentPath={location.pathname}
        title="TriplyETL - User settings"
      />
      <div className={getClassName(styles.innerContainer, "whiteSink")} ref={copyFrameRef}>
        <h4>TriplyETL</h4>
        <span>
          TriplyETL is <Label success>enabled</Label> for your account
        </span>
        <span>The TriplyETL License Key for your company is:</span>
        <MuiTextField
          className={showKey ? styles.key : getClassName(styles.key, styles.keyPointer)}
          aria-label="Triply ETL Key"
          value={showKey ? etlLicenseKey : "Show key"}
          onClick={() => (showKey ? null : setShowKey(true))}
          variant="outlined"
          InputProps={{
            readOnly: true,
            endAdornment: (
              <IconButton
                aria-label={showKey ? "Copy key" : "Show key"}
                className={styles.icon}
                onClick={() => (showKey ? copyToClipboard(etlLicenseKey) : null)}
                size="small"
              >
                <FontAwesomeIcon icon={showKey ? "clipboard" : "eye"} />
              </IconButton>
            ),
            style: { textAlign: "center", cursor: showKey ? "text" : "pointer" },
          }}
        />
        <p>
          See the{" "}
          <a href="https://docs.triply.cc/triply-etl/" target="_blank">
            TriplyETL documentation
          </a>{" "}
          for more information.
        </p>
      </div>
    </div>
  );
};

export default asyncConnect<GlobalState>([
  {
    promise: ({ match: { params }, store: { dispatch, getState } }) => {
      if (!accountIsCurrentAccount(getState(), params.account)) {
        return dispatch<any>(getAccountInfo(getState(), params.account, true));
      }
    },
  },
])(TriplyETL);
