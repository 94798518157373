import * as React from "react";
import { Models } from "@triply/utils";
import { InputField } from "./InputField.tsx";
import VariableSelectField from "./SelectField.tsx";

export interface QueryVariableFieldProps {
  onTestValueChange: (val: string) => void;
  getQueryString: () => string | undefined;
  datasetPath: string | undefined;
  testValue: string | undefined;
  variableDefinition: Models.VariableConfig;
  required?: boolean;
  fieldVariant?: "outlined" | "standard";
}

export const QueryVariableField: React.FC<QueryVariableFieldProps> = (props) => {
  if (props.variableDefinition.allowedValues && props.variableDefinition.allowedValues.length > 0) {
    return <VariableSelectField {...props} />;
  }
  return <InputField {...props} />;
};
