import { produce } from "immer";
import { Models, Routes } from "@triply/utils";
import { pruneEmptyClasses } from "#containers/Insights/helpers.tsx";
import { Dataset } from "#reducers/datasetManagement.ts";
import { Action, Actions, BeforeDispatch, GlobalAction, GlobalState } from "#reducers/index.ts";
import { Account } from "./accountCollection.ts";

export const LocalActions = {
  GET_CLASS_FREQUENCY: "triply/insights/GET_CLASS_FREQUENCY",
  GET_CLASS_FREQUENCY_SUCCESS: "triply/insights/GET_CLASS_FREQUENCY_SUCCESS",
  GET_CLASS_FREQUENCY_FAIL: "triply/insights/GET_CLASS_FREQUENCY_FAIL",
  GET_CLASS_HIERARCHY: "triply/insights/GET_CLASS_HIERARCHY",
  GET_CLASS_HIERARCHY_SUCCESS: "triply/insights/GET_CLASS_HIERARCHY_SUCCESS",
  GET_CLASS_HIERARCHY_FAIL: "triply/insights/GET_CLASS_HIERARCHY_FAIL",
} as const;

type GET_CLASS_FREQUENCY = GlobalAction<
  {
    types: [
      typeof LocalActions.GET_CLASS_FREQUENCY,
      typeof LocalActions.GET_CLASS_FREQUENCY_SUCCESS,
      typeof LocalActions.GET_CLASS_FREQUENCY_FAIL,
    ];
    datasetId: string;
    lastGraphsUpdateTime?: string;
    graphName: string;
    append: boolean;
  },
  Routes.datasets._account._dataset.insights.classFrequency.Get
>;

type GET_CLASS_HIERARCHY = GlobalAction<
  {
    types: [
      typeof LocalActions.GET_CLASS_HIERARCHY,
      typeof LocalActions.GET_CLASS_HIERARCHY_SUCCESS,
      typeof LocalActions.GET_CLASS_HIERARCHY_FAIL,
    ];
    datasetId: string;
    lastGraphsUpdateTime?: string;
  },
  Routes.datasets._account._dataset.insights.classHierarchy.Get
>;

export type LocalAction = GET_CLASS_FREQUENCY | GET_CLASS_HIERARCHY;

export interface State {
  [datasetId: string]: {
    classHierarchy?: Models.ClassHierarchy | null;
    classHierarchyLastGraphsUpdateTime?: string;
    classHierarchyError?: string;
    classFrequency: {
      [graphName: string]: { data: Models.ClassFrequency; nextPage?: string; lastGraphsUpdateTime?: string };
    };
  };
}

export const reducer = produce(
  (draftState: State, action: Action) => {
    switch (action.type) {
      case Actions.GET_CLASS_FREQUENCY_SUCCESS:
        if (!draftState[action.datasetId]) {
          draftState[action.datasetId] = { classHierarchy: undefined, classFrequency: {} };
        }
        draftState[action.datasetId].classFrequency[action.graphName] = {
          data: [
            ...(action.append ? draftState[action.datasetId].classFrequency[action.graphName].data : []),
            ...action.result,
          ],
          nextPage: action.meta.links.next ? action.meta.links.next.url : undefined,
          lastGraphsUpdateTime: action.lastGraphsUpdateTime,
        };
        return;

      case Actions.GET_CLASS_HIERARCHY_FAIL:
        if (!draftState[action.datasetId]) {
          draftState[action.datasetId] = {
            classHierarchy: undefined,
            classFrequency: {},
          };
        }
        draftState[action.datasetId].classHierarchy = undefined;
        draftState[action.datasetId].classHierarchyError = action.message;
        draftState[action.datasetId].classHierarchyLastGraphsUpdateTime = action.lastGraphsUpdateTime;
        return;

      case Actions.GET_CLASS_HIERARCHY_SUCCESS:
        if (!draftState[action.datasetId]) {
          draftState[action.datasetId] = { classHierarchy: undefined, classFrequency: {} };
        }
        try {
          draftState[action.datasetId].classHierarchy =
            action.result.length > 0 ? pruneEmptyClasses(action.result) : action.result;
          draftState[action.datasetId].classHierarchyError = undefined;
        } catch (e) {
          let message = "Failed to store class hierarchy";
          if (e instanceof Error) {
            message = e.message;
          }
          console.error(message);
          draftState[action.datasetId].classHierarchy = null;
          // pruneEmptyClasses might throw an error with message 'cycle'.
          draftState[action.datasetId].classHierarchyError = message;
        }
        draftState[action.datasetId].classHierarchyLastGraphsUpdateTime = action.lastGraphsUpdateTime;
        return;
    }
  },
  <State>{},
);

export function classFrequencyIsLoadedFor(state: GlobalState, datasetId: string, graphName: string) {
  return (
    !!state.insights[datasetId]?.classFrequency[graphName] &&
    state.insights[datasetId].classFrequency[graphName].lastGraphsUpdateTime ===
      state.datasetCollection[datasetId].lastGraphsUpdateTime
  );
}

export function classFrequencyIsOutdatedFor(state: GlobalState, datasetId: string, graphName: string) {
  return (
    !!state.insights[datasetId]?.classFrequency[graphName] &&
    state.insights[datasetId].classFrequency[graphName].lastGraphsUpdateTime !==
      state.datasetCollection[datasetId].lastGraphsUpdateTime
  );
}

export function getClassFrequencyFor(state: GlobalState, datasetId: string, graphName: string) {
  if (classFrequencyIsLoadedFor(state, datasetId, graphName)) {
    return state.insights[datasetId]?.classFrequency[graphName];
  }
}

export function getClassFrequency(opts: {
  datasetId: string;
  lastGraphsUpdateTime?: string;
  datasetPath: string;
  graphName: string;
  withLink?: string;
}): BeforeDispatch<GET_CLASS_FREQUENCY> {
  return {
    types: [Actions.GET_CLASS_FREQUENCY, Actions.GET_CLASS_FREQUENCY_SUCCESS, Actions.GET_CLASS_FREQUENCY_FAIL],
    promise: (client) => {
      if (opts.withLink)
        return client.req({
          url: opts.withLink,
          method: "get",
        });
      return client.req({
        pathname: `/datasets/${opts.datasetPath}/insights/classFrequency`,
        query: {
          graphName: opts.graphName,
        },
        method: "get",
      });
    },
    datasetId: opts.datasetId,
    graphName: opts.graphName,
    lastGraphsUpdateTime: opts.lastGraphsUpdateTime,
    append: !!opts.withLink,
  };
}

export function classHierarchyIsLoadedFor(state: GlobalState, datasetId: string) {
  return (
    (state.insights[datasetId]?.classHierarchy !== undefined ||
      state.insights[datasetId]?.classHierarchyError?.startsWith("Too much data to show")) &&
    state.insights[datasetId]?.classHierarchyLastGraphsUpdateTime ===
      state.datasetCollection[datasetId].lastGraphsUpdateTime
  );
}

export function classHierarchyIsOutdatedFor(state: GlobalState, datasetId: string) {
  return (
    (state.insights[datasetId]?.classHierarchy !== undefined ||
      state.insights[datasetId]?.classHierarchyError?.startsWith("Too much data to show")) &&
    state.insights[datasetId]?.classHierarchyLastGraphsUpdateTime !==
      state.datasetCollection[datasetId].lastGraphsUpdateTime
  );
}

export function getClassHierarchyFor(state: GlobalState, datasetId: string) {
  if (classHierarchyIsLoadedFor(state, datasetId)) {
    return state.insights[datasetId]?.classHierarchy;
  }
}

export function getClassHierarchy(opts: {
  datasetPath: string;
  datasetId: string;
  lastGraphsUpdateTime?: string;
}): BeforeDispatch<GET_CLASS_HIERARCHY> {
  return {
    types: [Actions.GET_CLASS_HIERARCHY, Actions.GET_CLASS_HIERARCHY_SUCCESS, Actions.GET_CLASS_HIERARCHY_FAIL],
    promise: (client) =>
      client.req({
        pathname: `/datasets/${opts.datasetPath}/insights/classHierarchy`,
        method: "get",
      }),
    datasetId: opts.datasetId,
    lastGraphsUpdateTime: opts.lastGraphsUpdateTime,
  };
}
