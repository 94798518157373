import { Alert, AppBar, Tab, Tabs } from "@mui/material";
import getClassName from "classnames";
import { push } from "connected-react-router";
import { ParsedQs } from "qs";
import React from "react";
import { useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { asyncConnect } from "redux-connect";
import useResizeObserver from "use-resize-observer";
import { prefixUtils } from "@triply/utils";
import { DatasetMetadata, ErrorPage, FlexContainer, SinkList } from "#components/index.ts";
import { IComponentProps } from "#containers/index.ts";
import { useDatasetPrefixes } from "#helpers/hooks/useDatasetPrefixes.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { parseSearchString, stringifyQuery } from "#helpers/utils.ts";
import { getCurrentAccount, useCurrentAccount } from "#reducers/app.ts";
import { getCurrentDataset, useCurrentDataset } from "#reducers/datasetManagement.ts";
import { GlobalState } from "#reducers/index.ts";
import {
  classHierarchyIsLoadedFor,
  classHierarchyIsOutdatedFor,
  getClassHierarchy,
  getClassHierarchyFor,
} from "#reducers/insights.ts";
import { showNotification } from "#reducers/notifications.ts";
import Bubbles from "./bubbles.tsx";
import { Datum, setColor, stratify } from "./helpers.tsx";
import SunBurst from "./sunBurst.tsx";
import TreeMap from "./treeMap.tsx";
import * as styles from "./style.scss";

const TypeTabs: React.FC<{ pathname: string; query: ParsedQs }> = (props) => {
  //Putting this in a separate component, as useResizeObserver ALWAYS needs to be used when initialized or initialized with useRef(null). In the ClassHierarchy
  //component we sometimes only draw an error page for example, in which case useResizeObserver throws an error.
  //useResizeObserver is needed for updating the current tab marker when (de)collapsing the dataset panel
  const { ref } = useResizeObserver();

  return (
    <Tabs ref={ref} value={props.query.type} indicatorColor="primary" style={{ minHeight: 0 }} variant="fullWidth">
      <Tab
        value="bubbles"
        className={styles.tab}
        component="div" // Component needs to be div as a link can't be a descendent of a button (default)
        icon={
          <Link
            title="Bubbles"
            className={getClassName("p-3 noLinkDecoration", styles.tabLink)}
            to={{
              pathname: props.pathname,
              search: stringifyQuery({ ...props.query, type: "bubbles" }),
              state: { preserveScrollPosition: true },
            }}
          >
            {bubbleSvg}
            <span>Bubbles</span>
          </Link>
        }
      />
      <Tab
        value="treemap"
        className={styles.tab}
        component="div"
        icon={
          <Link
            title="Treemap"
            className={getClassName("p-3 noLinkDecoration", styles.tabLink)}
            to={{
              pathname: props.pathname,
              search: stringifyQuery({ ...props.query, type: "treemap" }),
              state: { preserveScrollPosition: true },
            }}
          >
            {treemapSvg}
            <span>Treemap</span>
          </Link>
        }
      />
      <Tab
        value="sunburst"
        className={styles.tab}
        component="div"
        icon={
          <Link
            title="Sunburst"
            className={getClassName("p-3 noLinkDecoration", styles.tabLink)}
            to={{
              pathname: props.pathname,
              search: stringifyQuery({ ...props.query, type: "sunburst" }),
              state: { preserveScrollPosition: true },
            }}
          >
            {sunburstSvg}
            <span>Sunburst</span>
          </Link>
        }
      />
    </Tabs>
  );
};

const ClassHierarchy: React.FC<IComponentProps> = (props) => {
  const dispatch = useDispatch();
  const prefixes = useDatasetPrefixes();
  const currentAccount = useCurrentAccount();
  const currentDs = useCurrentDataset();

  const classHierarchyFlat = useSelector(
    (state: GlobalState) => currentDs && getClassHierarchyFor(state, currentDs.id),
  );

  const classHierarchyError = useSelector(
    (state: GlobalState) => currentDs && state.insights[currentDs.id]?.classHierarchyError,
  );

  const classHierarchyIsOutdated = useSelector(
    (state: GlobalState) => !!currentDs && classHierarchyIsOutdatedFor(state, currentDs.id),
  );

  const query = parseSearchString(props.location.search);

  React.useEffect(() => {
    if (classHierarchyIsOutdated && currentDs?.id && currentAccount?.accountName) {
      dispatch<typeof getClassHierarchy>(
        getClassHierarchy({
          datasetId: currentDs.id,
          datasetPath: `${currentAccount.accountName}/${currentDs.name}`,
          lastGraphsUpdateTime: currentDs.lastGraphsUpdateTime,
        }),
      )
        .then(() => dispatch(showNotification("The data was reloaded after a change in the dataset.", "info")))
        .catch(() => {});
    }
    // Fetch class hierarchy again when it is outdated (when a job finishes).
  }, [
    classHierarchyIsOutdated,
    currentAccount?.accountName,
    currentDs?.id,
    currentDs?.lastGraphsUpdateTime,
    currentDs?.name,
    dispatch,
  ]);

  const classHierarchyTree = React.useMemo(() => {
    if (!classHierarchyFlat || classHierarchyFlat.length === 0) return;
    const withPrefixInfo = classHierarchyFlat.map((datum) => ({
      ...datum,
      prefixInfo: prefixUtils.getPrefixInfoFromIri(datum.name, prefixes),
    }));
    const tree = stratify<Datum>(withPrefixInfo);
    setColor(tree);
    return tree;
  }, [classHierarchyFlat, prefixes]);

  const setFocus = React.useCallback(
    (classToFocus: string) => {
      dispatch(
        push({
          pathname: props.location.pathname,
          search: stringifyQuery({ type: query.type, focus: classToFocus }),
          state: { preserveScrollPosition: true },
        }),
      );
    },
    [props.location.pathname, query.type, dispatch],
  );

  if (!query.type) {
    return (
      <Redirect
        to={{
          pathname: props.location.pathname,
          search: stringifyQuery({ type: "bubbles" }),
          state: { preserveScrollPosition: true },
        }}
      />
    );
  }

  if (
    !currentAccount ||
    !currentDs ||
    (query.type !== "treemap" && query.type !== "bubbles" && query.type !== "sunburst")
  ) {
    return <ErrorPage statusCode={404} />;
  }

  const datasetMetadata = (
    <DatasetMetadata
      currentPath={props.location.pathname}
      currentAccount={currentAccount}
      currentDs={currentDs}
      title="Class hierarchy"
    />
  );

  if (!classHierarchyTree) {
    let message = "Cannot show subclass hierarchy because there are no instances of classes in this dataset.";
    if (classHierarchyError === "cycle") {
      message = "The class hierarchy cannot be visualized because it contains a cycle.";
    } else if (classHierarchyError?.startsWith("Too much data to show")) {
      message = "The class hierarchy cannot be visualized because it is too large.";
    }
    return (
      <FlexContainer>
        {datasetMetadata}
        <Alert className="my-5 shadow" severity="warning">
          {message} See{" "}
          <a
            className={styles.alertLink}
            href="https://docs.triply.cc/triply-db-getting-started/viewing-data/#class-hierarchy"
            target="_blank"
          >
            our documentation
          </a>{" "}
          for more information.
        </Alert>
      </FlexContainer>
    );
  }

  const focussedNode =
    (query.focus && classHierarchyTree.descendants().find((d) => d.data.name === query.focus)) || classHierarchyTree;
  return (
    <FlexContainer innerClassName={getClassName(styles.container)}>
      {datasetMetadata}
      <Alert className="my-5 shadow" severity="info">
        These are visualizations of the class hierarchy of this dataset. Check{" "}
        <a
          className={styles.alertLink}
          href="https://docs.triply.cc/triply-db-getting-started/viewing-data/#class-hierarchy"
          target="_blank"
        >
          our documentation
        </a>{" "}
        to learn how they are created.
      </Alert>

      <SinkList>
        <div>
          <AppBar position="static" className={styles.appBar}>
            <TypeTabs pathname={props.location.pathname} query={query} />
          </AppBar>
          {query.type === "treemap" && (
            <div className={getClassName(styles.item, styles.square)}>
              <TreeMap classHierarchyTree={classHierarchyTree} focussedNode={focussedNode} setFocus={setFocus} />
            </div>
          )}
          {query.type === "bubbles" && (
            <div className={getClassName(styles.item, styles.square)}>
              <Bubbles classHierarchyTree={classHierarchyTree} focussedNode={focussedNode} setFocus={setFocus} />
            </div>
          )}
          {query.type === "sunburst" && (
            <div className={getClassName(styles.item, styles.square)}>
              <SunBurst classHierarchyTree={classHierarchyTree} focussedNode={focussedNode} setFocus={setFocus} />
            </div>
          )}
        </div>
      </SinkList>
    </FlexContainer>
  );
};

export default asyncConnect<GlobalState>([
  {
    promise: ({ store: { dispatch, getState } }) => {
      const state = getState();
      const currentAccount = getCurrentAccount(state);
      const currentDs = getCurrentDataset(state);
      if (currentAccount && currentDs && currentDs.graphCount > 0 && !classHierarchyIsLoadedFor(state, currentDs.id)) {
        return dispatch<any>(
          getClassHierarchy({
            datasetId: currentDs.id,
            datasetPath: `${currentAccount.accountName}/${currentDs.name}`,
            lastGraphsUpdateTime: currentDs.lastGraphsUpdateTime,
          }),
        );
      }
    },
  },
])(ClassHierarchy) as typeof ClassHierarchy;

const treemapSvg = (
  <svg viewBox="0 0 19 18" style={{ height: "2em" }}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(-3, -3)">
      <path
        d="M20,3 L5,3 C3.9,3 3,3.9 3,5 L3,19 C3,20.1 3.9,21 5,21 L20,21 C21.1,21 22,20.1 22,19 L22,5 C22,3.9 21.1,3 20,3 Z M20,5 L20,8 L5,8 L5,5 L20,5 Z M15,19 L10,19 L10,10 L15,10 L15,19 Z M5,10 L8,10 L8,19 L5,19 L5,10 Z M17,19 L17,10 L20,10 L20,19 L17,19 Z"
        fill="currentColor"
      ></path>
    </g>
  </svg>
);

const bubbleSvg = (
  <svg viewBox="0 0 19 18" style={{ height: "2em" }}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(-3, -3)">
      <path
        d="M7,10 C4.79,10 3,11.79 3,14 C3,16.21 4.79,18 7,18 C9.21,18 11,16.21 11,14 C11,11.79 9.21,10 7,10 Z M7,16 C5.9,16 5,15.1 5,14 C5,12.9 5.9,12 7,12 C8.1,12 9,12.9 9,14 C9,15.1 8.1,16 7,16 Z M15.01,15 C13.36,15 12.01,16.35 12.01,18 C12.01,19.65 13.36,21 15.01,21 C16.66,21 18.01,19.65 18.01,18 C18.01,16.35 16.66,15 15.01,15 Z M15.01,19 C14.46,19 14.01,18.55 14.01,18 C14.01,17.45 14.46,17 15.01,17 C15.56,17 16.01,17.45 16.01,18 C16.01,18.55 15.56,19 15.01,19 Z M16.5,3 C13.47,3 11,5.47 11,8.5 C11,11.53 13.47,14 16.5,14 C19.53,14 22,11.53 22,8.5 C22,5.47 19.53,3 16.5,3 Z M16.5,12 C14.57,12 13,10.43 13,8.5 C13,6.57 14.57,5 16.5,5 C18.43,5 20,6.57 20,8.5 C20,10.43 18.43,12 16.5,12 Z"
        fill="currentColor"
      ></path>
    </g>
  </svg>
);

const sunburstSvg = (
  <svg viewBox="0 0 32 32" style={{ height: "2em" }}>
    <circle
      style={{ fill: "none", stroke: "currentColor", strokeWidth: 3, strokeLinejoin: "round", strokeMiterlimit: 10 }}
      cx="16"
      cy="16"
      r="13"
    />
    <circle
      style={{ fill: "none", stroke: "currentColor", strokeWidth: 3, strokeLinejoin: "round", strokeMiterlimit: 10 }}
      cx="16"
      cy="16"
      r="4"
    />
    <line
      style={{ fill: "none", stroke: "currentColor", strokeWidth: 3, strokeLinejoin: "round", strokeMiterlimit: 10 }}
      x1="16"
      y1="3"
      x2="16"
      y2="12"
    />
    <line
      style={{ fill: "none", stroke: "currentColor", strokeWidth: 3, strokeLinejoin: "round", strokeMiterlimit: 10 }}
      x1="20"
      y1="16"
      x2="29"
      y2="16"
    />
    <line
      style={{ fill: "none", stroke: "currentColor", strokeWidth: 3, strokeLinejoin: "round", strokeMiterlimit: 10 }}
      x1="18.8"
      y1="18.8"
      x2="25.2"
      y2="25.2"
    />
  </svg>
);
