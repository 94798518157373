import { flatMap } from "lodash-es";
import { factories } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize.js";
import useRemovePrefixes from "#helpers/hooks/useRemovePrefixes.ts";
import { Properties } from "./Types";

const factory = factories.compliant;
export default function formValuesToSparqlValues(
  subject: string,
  properties: Properties,
  removePrefixes: ReturnType<typeof useRemovePrefixes>,
): string[] {
  return flatMap(properties, (propertyValues, key) => {
    const predicate = key.replace(/ /g, ".");
    return flatMap(propertyValues, (property) => {
      if (!property || !property.value) return [];
      switch (property.nodeKind) {
        case "IRI":
          return [
            `${termToString(factory.namedNode(subject))} ${termToString(factory.namedNode(predicate))} ${termToString(factory.namedNode(removePrefixes(property.value.trim())))}`,
          ];
        case "BlankNode":
          return [
            `${termToString(factory.namedNode(subject))} ${termToString(factory.namedNode(predicate))} ${termToString(factory.namedNode(property.value))}`,
            `${termToString(factory.namedNode(property.value))} <http://www.w3.org/1999/02/22-rdf-syntax-ns#type> ${termToString(factory.namedNode(property.type))}`,
            ...formValuesToSparqlValues(property.value, property.properties, removePrefixes),
          ];
        case "Literal":
          return [
            `${termToString(factory.namedNode(subject))} ${termToString(factory.namedNode(predicate))} ${termToString(
              factory.literal(
                property.value.trim(),
                (property.datatype === "http://www.w3.org/1999/02/22-rdf-syntax-ns#langString" &&
                  (property.language || "nl")) ||
                  (property.datatype && factory.namedNode(property.datatype)),
              ),
            )}`,
          ];
      }
    });
  });
}
