import { Alert, Skeleton } from "@mui/material";
import getClassName from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import { Button } from "#components/index.ts";
import { useDatasetPrefixes } from "#helpers/hooks/useDatasetPrefixes.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { useCurrentAccount } from "#reducers/app.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import { GlobalState } from "#reducers/index.ts";
import { getClassFrequency, getClassFrequencyFor } from "#reducers/insights.ts";
import BarChart from "./BarChart.tsx";
import * as styles from "./style.scss";

type Props = {
  graphName: string;
};

const ClassFrequency: React.FC<Props> = ({ graphName }) => {
  const dispatch = useDispatch();

  const currentAccount = useCurrentAccount();
  const currentDs = useCurrentDataset();
  const classFrequency = useSelector(
    (state: GlobalState) =>
      (currentDs && graphName && getClassFrequencyFor(state, currentDs.id, graphName)) || undefined,
  );
  const prefixes = useDatasetPrefixes();

  React.useEffect(() => {
    if (currentDs?.id && currentAccount?.accountName) {
      dispatch<typeof getClassFrequency>(
        getClassFrequency({
          datasetId: currentDs.id,
          datasetPath: `${currentAccount.accountName}/${currentDs.name}`,
          graphName: graphName,
          lastGraphsUpdateTime: currentDs.lastGraphsUpdateTime,
        }),
      ).catch(() => {});
    }
  }, [
    currentAccount?.accountName,
    currentDs?.id,
    currentDs?.lastGraphsUpdateTime,
    currentDs?.name,
    dispatch,
    graphName,
  ]);

  const loadNextPage = React.useCallback(() => {
    if (currentDs?.id && currentAccount?.accountName && classFrequency?.nextPage) {
      dispatch(
        getClassFrequency({
          datasetId: currentDs.id,
          datasetPath: `${currentAccount.accountName}/${currentDs.name}`,
          graphName: graphName,
          lastGraphsUpdateTime: currentDs.lastGraphsUpdateTime,
          withLink: classFrequency?.nextPage,
        }),
      );
    }
  }, [
    classFrequency?.nextPage,
    currentAccount?.accountName,
    currentDs?.id,
    currentDs?.lastGraphsUpdateTime,
    currentDs?.name,
    dispatch,
    graphName,
  ]);

  if (!currentAccount || !currentDs) {
    return <Alert severity="error">Data not found</Alert>;
  }

  if (!classFrequency?.data) {
    return <Skeleton variant="rectangular" />;
  }
  if (classFrequency.data.length === 0) {
    return <Alert severity="warning">The class frequency cannot be visualized because it is too large.</Alert>;
  }
  return (
    <div className={getClassName(styles.item, styles.barChartItem)}>
      <BarChart key={graphName} data={classFrequency.data} prefixes={prefixes} />
      {classFrequency.nextPage && (
        <div className={styles.showMore}>
          <Button variant="text" size="small" onClick={loadNextPage}>
            Show more...
          </Button>
        </div>
      )}
    </div>
  );
};

export default React.memo(ClassFrequency);
