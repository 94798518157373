import { Alert, Typography } from "@mui/material";
import * as React from "react";
import { LiteralUtils } from "@triply/utils";
import { formatNumericTerm } from "@triply/utils-private/formatting.js";
import { FontAwesomeIcon } from "#components/index.ts";
import { DashViewer, DashViewerProps } from ".";
import * as styles from "./style.scss";

const LiteralViewerComponent: React.FC<DashViewerProps> = ({ property }) => {
  if (property.nodeKind !== "Literal") return <Alert severity="error">Wrongly configured Labelviewer</Alert>;

  if (property.datatype) {
    if (LiteralUtils.isBooleanDatatype(property.datatype)) {
      if (property.value === "true") {
        return (
          <span aria-label={property.value}>
            <FontAwesomeIcon icon="check" />
          </span>
        );
      } else if (property.value === "false") {
        return (
          <span aria-label={property.value}>
            <FontAwesomeIcon icon="xmark" />
          </span>
        );
      }
    }
    if (LiteralUtils.isNumericDatatype(property.datatype) && !Number.isNaN(+property.value)) {
      return <span>{formatNumericTerm(property.value, property.datatype)}</span>;
    }
  }

  return (
    <span>
      <Typography variant="body1" className={styles.multiline}>
        {property.value}
      </Typography>
    </span>
  );
};

const LiteralViewer: DashViewer = {
  component: LiteralViewerComponent,
  getScore({ nodeKind }) {
    if (nodeKind === "Literal") return 1;
    return 0;
  },
};

export default LiteralViewer;
