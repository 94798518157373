import { DialogActions, DialogContent, DialogTitle, Divider, Paper, Popover } from "@mui/material";
import getClassName from "classnames";
import dedent from "dedent";
import * as React from "react";
import { Button, FontAwesomeIcon, Markdown } from "../..";
import { VisualizationLabel } from ".";
import * as styles from "./styles.scss";

interface Props {
  visualization: VisualizationLabel;
}

const DocumentationDialog: React.FC<Props> = ({ visualization }) => {
  const [open, setOpen] = React.useState(false);
  const anchor = React.useRef<HTMLDivElement>(null);
  const menuId = React.useId();
  return (
    <div ref={anchor}>
      <Button
        color="primary"
        variant="outlined"
        className={getClassName("mx-2", styles.documentationButton)}
        aria-controls={menuId}
        aria-expanded={open ? "true" : "false"}
        title="Open visualization documentation"
        onClick={() => setOpen((open) => !open)}
        startIcon={<FontAwesomeIcon icon={"book"} />}
      />
      <Popover
        open={open}
        anchorEl={anchor.current}
        onClose={() => setOpen(false)}
        disablePortal
        className={styles.popper}
      >
        <Paper className={getClassName(styles.documentationPaper, "shadow")} variant="outlined" id={menuId}>
          <DialogTitle>{visualization}</DialogTitle>
          <Divider />
          <DialogContent className={styles.documentationContent}>
            <Markdown>{DESCRIPTIONS[visualization]}</Markdown>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button variant="text" onClick={() => setOpen(false)}>
              Close
            </Button>
          </DialogActions>
        </Paper>
      </Popover>
    </div>
  );
};

export default DocumentationDialog;

const MARKUP_SNIPPET = `
## Markup data-types

| **Markup language**                                | **Datatype**                                       |
| :------------------------------------------------- | :------------------------------------------------- |
| HTML                                               | \`http://www.w3.org/1999/02/22-rdf-syntax-ns#HTML\`  |
| Markdown                                           | \`https://triplydb.com/Triply/vocab/def/markdown\`\* |
| [Mermaid](https://mermaid-js.github.io/mermaid/#/) | \`https://triplydb.com/Triply/vocab/def/mermaid\`\*  |
| Plain text                                         | Other                                              |

\* This is currently a placeholder IRI. If you find a (dereferenceable) IRI for one of these datatypes please [contact us](//triply.cc/contact).

`;

const TABLE_DESCRIPTION = dedent`
This view allows SPARQL results to be displayed in a table. Each
column in the table corresponds to a variable that belongs to the
outer projection. Each row in the table corresponds to one query
result. Each cell contains an RDF term or \`NULL\`.
`;

const RESPONSE_DESCRIPTION = "This view shows the body of the response.";

const JSON_DESCRIPTION = "https://github.com/rubensworks/sparqljson-to-tree.js";

const GALLERY_DESCRIPTION = dedent`
This view allows SPARQL results to be displayed as a gallery.
The content of the gallery items can be specified in plain text, HTML, Markdown or [mermaid](https://mermaid.js.org/intro/syntax-reference.html).
A template is available in the gallery settings.
Content can also be rendered without the gallery layout and template to allow for custom full-size elements.

${MARKUP_SNIPPET}`;

const CHART_DESCRIPTION = dedent`
The chart plugin renders geographical, temporal and numerical data in interactive charts such as bar-, line- and pie charts.
`;

const GEO_DESCRIPTION = dedent`
This view allows SPARQL results that contain GeoSPARQL semantics to be
automatically interpreted and displayed on a 2D map.

## Variables

This view recognizes the following SPARQL variable names:

| **Variable name**   | **Purpose**                                                                                                                                                                             |
| ------------------- | --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- |
| \`?x\`              | An arbitrary variable name that is bound to literals with datatype IRI \`geo:wktLiteral\`, and whose name is the prefix of the other variable names in this table.                      |
| \`?xColor\`         | The color of the shape bound to \`?x\`.                                                                                                                                                 |
| \`?xLabel\`         | The text or Markup content of popups that appear when clicking the shape bound to \`?x\`.                                                                                               |
| \`?xTooltip\`       | Text or Markup that will appear when the shape of bound to \`?x\` is hovered                                                                                                            |
| \`?xWeight\`        | Numeric value between 1-10, used in the heatmap                                                                                                                                         |
| \`?xHeight\`        | The height in meters of the 2.5D shape that is based on the 2D shape that is bound to \`?x\`. This is only applicable for the \`Tilted\` perspective.                                   |
| \`?xZ\`             | The height in meters at which the 2.5D shape that is based on the 2D shape that is bound to \`?x\` starts. This is only applicable for the \`Tilted\` perspective and \`gLTF\` models.  |
| \`?xGltfUrl\`       | URL pointing to a \`gLTF\` model with \`?x\` as a zero point of that model. Expects \`?x\` to be a point geometry                                                                       |
| \`?xRotationX\`     | Rotation of the X axis of the model in radians, default π/2                                                                                                                             |
| \`?xRotationY\`     | Rotation of the Y axis of the model in radians                                                                                                                                          |
| \`?xRotationZ\`     | Rotation of the Z axis of the model in radians                                                                                                                                          |
| \`?mapEndpoint\`    | A URL pointing to a WMS tile-server                                                                                                                                                     |

## Color values

Variable \`?xColor\` must include a value of the following types:

- [CSS color names](https://developer.mozilla.org/en-US/docs/Web/CSS/color_value).
- [RGB color codes](https://developer.mozilla.org/en-US/docs/Web/CSS/color_value).
- [HSL color codes](https://developer.mozilla.org/en-US/docs/Web/CSS/color_value).
- **Gradients**: Strings of the form \`{{PALETTE}},{{VALUE}}\`, where \`{{VALUE}}\` is a floating-point number between 0.0 and 1.0 and \`{{PALETTE}}\` is the name of a color palette. We support color schemes from the [Colormap](https://www.npmjs.com/package/colormap) and [Color Brewer](http://colorbrewer2.org/#type=sequential&scheme=BuGn&n=3) libraries

##### WMS tile-servers

To include layers from a WMS tile-server, use the \`mapEndpoint\` variable to refer to a server. The plugin will then retrieve the layer information from the server. Usage of the layers can be toggled using the layer selector. Try this one:

- <https://maps.heigit.org/histosm/wms>

##### 3D Tiles

In the configuration menu you can specify a URL pointing to a [3D-tiles](https://github.com/CesiumGS/3d-tiles/tree/main/specification) json file].
`;

const PIVOT_DESCRIPTION = dedent`
This view renders SPARQL results in an interactive pivot table where you are able to aggregate the results by dragging your binding variables to columns or rows.
`;

const TIMELINE_DESCRIPTION = dedent`
The SPARQL timeline renders the SPARQL results on a Timeline ([example](https://triplydb.com/DBpedia-association/-/queries/timeline-cars))
To get started with this visualization you need at least a result containing a \`?eventStart\` or \`?eventDate\` with either a \`?eventDescription\`, \`?eventLabel\` or a \`?eventMedia\`. (Combinations are also possible)
The following parameters can be used, Parameters in _Italic_ are experimental:

| **Variable name**        | **Purpose**                                                                                                                                   |
| ------------------------ | --------------------------------------------------------------------------------------------------------------------------------------------- |
| \`?eventStart\`            | A date when an event started                                                                                                                |
| \`?eventEnd\`              | A date when an event Stopped                                                                                                                |
| \`?eventDate\`             | A date when an event happened                                                                                                               |
| \`?eventDescription\`      | Text/Markup about the event                                                                                                                 |
| \`?eventLabel\`            | Text/Markup title                                                                                                                           |
| \`?eventMedia\`            | Link to most forms of media see [documentation](https://timeline.knightlab.com/docs/media-types.html) for which type of links are supported |
| _\`?eventType\`_           | Groups events                                                                                                                               |
| _\`?eventColor\`_          | Colors event                                                                                                                                |
| _\`?eventBackground\`_     | Background of the event when selected                                                                                                       |
| _\`?eventMediaCaption\`_   | Text/Markup caption of the Media                                                                                                            |
| _\`?eventMediaCredit\`_    | Text/Markup credit of the Media                                                                                                             |
| _\`?eventMediaThumbnail\`_ | The thumbnail of Media                                                                                                                      |
| _\`?eventMediaAlt\`_       | The Alt text of the Media                                                                                                                   |
| _\`?eventMediaTitle\`_     | The Title of the Media                                                                                                                      |
| _\`?eventMediaLink\`_      | The URL the image should link to                                                                                                            |

${MARKUP_SNIPPET}`;

const NETWORK_DESCRIPTION = dedent`
This view renders SPARQL Construct results in a graph representation. The maximum amount of results that can be visualized is 1.000 due to performance.
`;
const LD_FRAME_DESCRIPTION = dedent`
This view allows SPARQL construct queries to be framed as a JSON-LD response. This is useful for transforming a linked data result into a json tree-like structure for easier use in API's. You can read more about this [here](https://docs.triply.cc/generics/JSON-LD-frames/#json-ld-framing)
`;

const DESCRIPTIONS = {
  Table: TABLE_DESCRIPTION,
  Response: RESPONSE_DESCRIPTION,
  JSON: JSON_DESCRIPTION,
  Geo: GEO_DESCRIPTION,
  Gallery: GALLERY_DESCRIPTION,
  Charts: CHART_DESCRIPTION,
  Pivot: PIVOT_DESCRIPTION,
  Timeline: TIMELINE_DESCRIPTION,
  Network: NETWORK_DESCRIPTION,
  LDFrame: LD_FRAME_DESCRIPTION,
} as const;
