import * as React from "react";
import { SelectResult } from "../../../components/Sparql/SparqlUtils";
import useConstructUrlToApi from "../../../helpers/hooks/useConstructUrlToApi";
import { useCurrentDataset } from "../../../reducers/datasetManagement";

const useSparql = () => {
  const currentDs = useCurrentDataset()!;
  const sparqlUrl = useConstructUrlToApi()({
    pathname: `/_console/sparql`,
    fromBrowser: true,
  });

  const accountName = currentDs.owner.accountName;
  const datasetName = currentDs.name;

  const sparql = React.useCallback(
    async (query: string, abortSignal?: AbortSignal) => {
      const response = await fetch(sparqlUrl, {
        credentials: "same-origin",
        signal: abortSignal,
        method: "POST",
        headers: { Accept: "application/sparql-results+json" },
        body: new URLSearchParams({ queryString: query, account: accountName, dataset: datasetName }),
      });
      const result = await response.json();
      return result as SelectResult;
    },
    [sparqlUrl, accountName, datasetName],
  );

  return sparql;
};

export default useSparql;
