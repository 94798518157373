import { IconButton, Paper, Table, TableContainer, Tooltip } from "@mui/material";
import {
  CellContext,
  ColumnDef,
  createColumnHelper,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
  useReactTable,
} from "@tanstack/react-table";
import getClassName from "classnames";
import { compact } from "lodash-es";
import * as React from "react";
import { Link } from "react-router-dom";
import { Models } from "@triply/utils";
import { Prefix } from "@triply/utils/prefixUtils.js";
import FontAwesomeIcon from "#components/FontAwesomeIcon/index.tsx";
import { Avatar } from "#components/index.ts";
import IRI from "#components/IRI/index.tsx";
import { TableHumanizedDateRenderer } from "#components/ReactTableUtils/DateRenderer.tsx";
import { NumberRenderer, TableFooter } from "#components/ReactTableUtils/index.ts";
import ReactTableBody from "#components/ReactTableUtils/TableBody.tsx";
import TableHeader from "#components/ReactTableUtils/TableHeader.tsx";
import useAcl from "#helpers/hooks/useAcl.ts";
import { useDatasetPrefixes } from "#helpers/hooks/useDatasetPrefixes.ts";
import { useCurrentAccount } from "#reducers/app.ts";
import { createDatasetPrefix, useCurrentDataset } from "#reducers/datasetManagement.ts";
import { Graph, Graphs } from "#reducers/graphs.ts";
import useDispatch from "../../helpers/hooks/useDispatch.ts";
import ClassFrequency from "./ClassFrequency/index.tsx";
import Actions from "./Actions.tsx";
import getShortIriDict, { ShortIriDict } from "./getShortIriDict.ts";
import * as styles from "./style.scss";

function getLocalName(iri: string) {
  return iri.replace(/.*[\\/#](?=.+)/g, "");
}
function getPrefixAndLabel(prefixes: Prefix[], iri: string) {
  for (const p of prefixes) {
    if (iri.startsWith(p.iri) && iri.length > p.iri.length) {
      return {
        label: iri.slice(p.iri.length),
        prefix: p.prefixLabel,
      };
    }
  }
  return { label: getLocalName(iri) };
}

function prefixedOrLocalName(prefixes: Prefix[], shortIriDict: ShortIriDict, iri: string) {
  // Assumes everything is resolved
  if (shortIriDict[iri]) return shortIriDict[iri];
  const { prefix, label } = getPrefixAndLabel(prefixes, iri);
  return prefix ? `${prefix}:${label}` : label;
}

const Source: React.FC<CellContext<Graph, any>> = ({ row: { original: g } }) => {
  if (g.qualityReportFor) return <>Generated data quality report</>;
  if (g.fromPipeline) return <>SPARQL query</>;
  if (g.importedFrom) {
    const tooltip = (
      <>
        {g.importedFrom.graphName !== g.graphName && (
          <Tooltip title={`Renamed from ${g.importedFrom.graphName}`} placement="right">
            <div>
              <FontAwesomeIcon className={getClassName("ml-1", styles.infoIcon)} icon={["fas", "info"]} fixedWidth />
            </div>
          </Tooltip>
        )}
      </>
    );
    if (!g.importedFrom.dataset) return <div className="flex center g-2">Unavailable dataset {tooltip}</div>;
    const linkTo = g.importedFrom.dataset.substring(g.importedFrom.dataset.indexOf("datasets") + "datasets".length);
    return (
      <div className="flex center g-2">
        <Avatar
          size="xs"
          avatarName={g.importedFrom.datasetName || ""}
          avatarUrl={g.importedFrom.datasetAvatarUrl}
          alt=""
          className="mb-1"
          linkTo={linkTo}
        />
        <Link to={linkTo}>{g.importedFrom.datasetName}</Link>
        {tooltip}
      </div>
    );
  }

  return <>Uploaded</>;
};

const Name: React.FC<CellContext<Graph, string> & { shortIriDict: ShortIriDict }> = ({ cell, shortIriDict, row }) => {
  const prefixes = useDatasetPrefixes();
  const currentDs = useCurrentDataset();
  const graphName = row.original.graphName;
  const acl = useAcl();
  const dispatch = useDispatch();
  const allowedToCreatePrefixOnData = acl.check({
    action: "editDatasetMetadata",
    context: {
      roleInOwnerAccount: acl.getRoleInAccount(currentDs?.owner),
      accessLevel: currentDs?.accessLevel,
      newAccessLevel: undefined,
    },
  }).granted;
  const createPrefix = React.useCallback(
    (prefix: Models.PrefixUpdate) => {
      if (currentDs) {
        return dispatch<typeof createDatasetPrefix>(createDatasetPrefix(prefix, currentDs?.owner, currentDs))
          .then(() => true)
          .catch((e) => {
            return e.message;
          });
      }
      throw new Error("Dataset not found");
    },
    [dispatch, currentDs],
  );

  return (
    <div draggable onDragStart={(e) => e.dataTransfer.setData("text/plain", graphName)}>
      <IRI
        preferRouter
        to={`/${currentDs!.owner.accountName}/${currentDs!.name}/table?graph=${encodeURIComponent(graphName)}`}
        prefixes={prefixes}
        position="graph"
        shortenIri={(iri) => {
          return shortIriDict[iri];
        }}
        datasetPath={`${currentDs?.owner.accountName}/${currentDs?.name}`}
        onCreatePrefix={allowedToCreatePrefixOnData ? createPrefix : undefined}
      >
        {graphName}
      </IRI>
    </div>
  );
};

const Expand: React.FC<CellContext<Graph, string>> = ({ cell, row }) => {
  if (row.getIsExpanded()) {
    return (
      <IconButton
        size="small"
        onClick={row.getToggleExpandedHandler()}
        title="Hide class frequency"
        aria-label="Hide class frequency"
      >
        <FontAwesomeIcon icon="chevron-down" fixedWidth />
      </IconButton>
    );
  } else {
    return (
      <IconButton
        size="small"
        onClick={row.getToggleExpandedHandler()}
        title="Show class frequency"
        aria-label="Show class frequency"
      >
        <FontAwesomeIcon icon="chevron-right" fixedWidth />
      </IconButton>
    );
  }
};

const ClassFrequencyRow = ({ row }: { row: Row<Graph> }) => {
  return <ClassFrequency graphName={row.original.graphName} />;
};

const columnHelper = createColumnHelper<Graph>();
const getColumns: (opts: {
  graphs: Graphs;
  prefixes: Prefix[];
  mayManageGraphs: boolean;
}) => ColumnDef<Graph, any>[] = ({ graphs, prefixes, mayManageGraphs }) => {
  const shortIriDict = getShortIriDict(
    graphs.map((graph) => graph.graphName),
    prefixes,
  );
  return compact([
    columnHelper.accessor((g) => g, {
      id: "expand",
      header: "",
      cell: Expand,
      enableColumnFilter: false,
      enableGlobalFilter: false,
    }),
    columnHelper.accessor((g) => prefixedOrLocalName(prefixes, shortIriDict, g.graphName), {
      header: "Name",
      cell: (props: any) => <Name {...props} shortIriDict={shortIriDict} />,
      sortingFn: "alphanumeric",
      filterFn: "includesString",
    }),
    columnHelper.accessor("numberOfStatements", {
      header: "Number of statements",
      cell: NumberRenderer,
      filterFn: "inNumberRange",
      enableGlobalFilter: false,
    }),
    columnHelper.accessor((g) => ("uploadedAt" in g ? g.uploadedAt! : g.importedAt!), {
      header: "Created",
      cell: TableHumanizedDateRenderer,
      enableColumnFilter: false,
    }),
    columnHelper.accessor(
      (g) => {
        if (g.importedFrom) {
          if (g.importedFrom.datasetName) {
            return g.importedFrom.datasetName;
          } else {
            return "Unavailable dataset";
          }
        }
        if (g.qualityReportFor) {
          return "Generated data quality report";
        }
        if (g.fromPipeline) {
          return "SPARQL query";
        }
        return "Uploaded";
      },
      {
        header: "Source",
        cell: Source,
        sortingFn: "alphanumeric",
        filterFn: "equals",
      },
    ),
    mayManageGraphs
      ? columnHelper.accessor(
          (g) => {
            if (g.qualityReport) return "Data quality issues";
            return "No issues";
          },
          {
            id: "actions",
            header: "",
            cell: Actions,
            filterFn: "equals",
            enableSorting: false,
          },
        )
      : columnHelper.display({
          id: "actions",
          cell: Actions,
        }),
  ]);
};

const GraphTable: React.FC<{
  graphs: Graphs;
}> = ({ graphs }) => {
  const prefixes = useDatasetPrefixes();
  const currentAccount = useCurrentAccount();
  const acl = useAcl();
  const mayManageGraphs = acl.check({
    action: "manageGraphs",
    context: { roleInOwnerAccount: acl.getRoleInAccount(currentAccount) },
  }).granted;
  const columns = React.useMemo(
    () => getColumns({ graphs, prefixes, mayManageGraphs }),
    [graphs, prefixes, mayManageGraphs],
  );
  const [expanded, setExpanded] = React.useState<ExpandedState>({});
  const table = useReactTable<Graph>({
    columns: columns,
    data: graphs,
    initialState: {
      pagination: {
        pageSize: 20,
      },
      sorting: [{ id: "Created", desc: true }],
    },
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    getRowId: (graph) => graph.id,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand: () => true,
  });
  return (
    <Paper className={getClassName("mt-5 pt-3", { "pb-3": graphs.length <= 10 })}>
      <TableContainer>
        <Table size="small">
          <TableHeader headerGroups={table.getHeaderGroups()} hideFilters={graphs.length <= 1} />
          <ReactTableBody
            rows={table.getRowModel().rows}
            loading={false}
            error={undefined}
            columnCount={0}
            subComponent={ClassFrequencyRow}
          />
          {graphs.length > 10 && (
            <TableFooter
              currentPage={table.getState().pagination.pageIndex}
              pageSize={table.getState().pagination.pageSize}
              rowCount={table.getPrePaginationRowModel().rows.length}
              onChangePage={table.setPageIndex}
              onChangeRowsPerPage={table.setPageSize}
              className={styles.footer}
            />
          )}
        </Table>
      </TableContainer>
    </Paper>
  );
};
const MemoedGraphTable = React.memo(GraphTable);
export default MemoedGraphTable;
