import * as React from "react";
import { useLocation } from "react-router";
import { ErrorPage, FlexContainer, Meta } from "#components/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import EnforceTfa from "./EnforceTfa.tsx";
import Sessions from "./Sessions.tsx";
import SignUp from "./SignUp.tsx";

const AdminSecurity: React.FC<{}> = ({}) => {
  const acl = useAcl();

  if (!acl.check({ action: "editInstanceMetadata" }).granted) return <ErrorPage statusCode={401} />;
  return (
    <FlexContainer>
      <SecurityMeta />
      <div className="whiteSink">
        <h3>Authentication</h3>
        <SignUp />
        <EnforceTfa />
        <Sessions />
      </div>
    </FlexContainer>
  );
};

export default AdminSecurity;

const SecurityMeta: React.FC = () => {
  const location = useLocation(); // Prevent re-rendering of the component when the location changes
  return <Meta currentPath={location.pathname} title="Security" />;
};
