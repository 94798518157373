import { DatasetPublic } from "@triply/utils/Models.js";
import { injectVariablesInPlace, parsers, serialize } from "@triplydb/sparql-ast";
import formValuesToSparqlValues from "./formValuesToSparqlValues";
import { getSparqlBasedConstrains } from "./getSparqlBasedConstrains";
import { FormValues } from "./Types";

export class SparqlBasedConstraintError extends Error {}

export const executeSparqlBasedConstraints = async ({
  sparqlUrl,
  resource,
  values,
  initialValues,
  removePrefixes,
  dataset,
}: {
  sparqlUrl: string;
  resource: string;
  values: FormValues;
  initialValues?: FormValues;
  removePrefixes: any;
  dataset: DatasetPublic;
}) => {
  const constraints = values.type?.id ? await getSparqlBasedConstrains(values.type.id, sparqlUrl, dataset) : [];
  if (!constraints.length) return;

  const rawPropertyValues = formValuesToSparqlValues(resource, values.properties, removePrefixes);
  const positive = rawPropertyValues ? rawPropertyValues.join(". \n") + (rawPropertyValues.length ? "." : "") : "";
  const initialTriples = initialValues
    ? formValuesToSparqlValues(resource, initialValues.properties, removePrefixes)
    : [];
  const uniqueNegativeTriples = initialTriples.filter((triple) => !rawPropertyValues.includes(triple));
  const negative = uniqueNegativeTriples.join(". \n") + (uniqueNegativeTriples.length ? "." : "");

  const constraintPromises = constraints.map(async (constraint) => {
    const parsedQuery = parsers.lenient(constraint.query, { baseIri: "https://triplydb.com/" });
    const mutatedQuery = injectVariablesInPlace(parsedQuery, {
      declarations: [{ name: "this", termType: "NamedNode" }],
      values: { this: resource },
    });
    const boundQuery = serialize(mutatedQuery);

    const response = await fetch(sparqlUrl, {
      credentials: "same-origin",
      method: "POST",
      headers: { Accept: "application/json", "Content-Type": "application/json" },
      body: JSON.stringify({
        queryString: boundQuery,
        account: dataset.owner.accountName,
        dataset: dataset.name,
        extraNquads: {
          positive: positive ? positive : undefined,
          negative: negative ? negative : undefined,
        },
      }),
    });

    const results = await response.json();
    if (results.length)
      throw new SparqlBasedConstraintError(constraint.message ?? "A SPARQL based constraint was not satisfied");
  });

  await Promise.all(constraintPromises);
};
