import { diff, IChange } from "json-diff-ts";
import { FormValues } from "./Types";

type Actions = "UPDATE" | "REMOVE" | "ADD";
type ChangeObject = {
  type: Actions;
  property: string;
  value: { oldValue: string; newValue: string } | string;
};
type Properties = {
  [property: string]: PropBody[];
};
interface PropBody {
  properties?: Properties;
  value?: number | string | boolean;
}

// These vales are removed every time a field is updated, they are redundant.
const VALUES_TO_SKIP: readonly string[] = ["predicate", "rawValue", "language", "key", "properties", "datatype", "iri"];

function hasValue(change: IChange | PropBody): boolean {
  const value = change.value;
  return (
    value !== null &&
    value !== undefined &&
    value !== "" &&
    (typeof value !== "object" || (value.value !== null && value.value !== undefined && value.value !== ""))
  );
}

function extractSubProperties(action: Actions, properties: Properties): ChangeObject[] {
  return Object.keys(properties).reduce((changes: ChangeObject[], property) => {
    if (properties[property].length !== 0) {
      properties[property].forEach((propBody: PropBody) => {
        if (propBody.properties) {
          changes.push(...extractSubProperties(action, propBody.properties));
        } else if (hasValue(propBody)) {
          const change: ChangeObject = {
            type: action,
            property,
            value: String(propBody.value),
          };
          changes.push(change);
        }
      });
    }
    return changes;
  }, []);
}
function createChange(change: IChange): ChangeObject | undefined {
  if (hasValue(change)) {
    return change.type === "UPDATE" && change.oldValue
      ? {
          property: change.key,
          type: change.type,
          value:
            typeof change.value === "object"
              ? { oldValue: change.value.oldValue, newValue: change.value.value }
              : { oldValue: change.oldValue, newValue: change.value },
        }
      : {
          property: change.key,
          type: change.type,
          value: typeof change.value === "object" ? change.value.value : change.value,
        };
  }
}
function parseChanges(changes: IChange[]): ChangeObject[] {
  const result: ChangeObject[] = [];
  changes.forEach((change) => {
    if (change.changes) {
      change.changes.forEach((initialChange) => {
        if (!isNaN(Number(initialChange.key)) || (initialChange.key === "value" && initialChange.type === "UPDATE"))
          initialChange.key = change.key;
      });
      const processedChanges = parseChanges(change.changes);
      result.push(...processedChanges);
    } else if (change.value && Array.isArray(change.value)) {
      change.value.forEach((value) => {
        if (value.properties) {
          result.push(...extractSubProperties(change.type, value.properties));
        } else {
          const createdChange = createChange({ ...change, value: value });
          if (createdChange) result.push(createdChange);
        }
      });
    } else if (change.value && typeof change.value === "object" && change.value.properties) {
      result.push(...extractSubProperties(change.type, change.value.properties));
    } else if (change.value) {
      const createdChange = createChange(change);
      if (createdChange) result.push(createdChange);
    }
  });
  return result;
}
export function getChangeDiff(values: FormValues, initialValues?: FormValues): string {
  const initialDiff = initialValues ? diff(initialValues, values)[0]?.changes || [] : diff(initialValues, values);
  const newChanges = parseChanges(initialDiff);
  return newChanges
    .map((newChange) => {
      switch (newChange.type) {
        case "ADD":
          return `Created: property ${newChange.property} with value ${newChange.value} `;
        case "REMOVE":
          if (VALUES_TO_SKIP.includes(newChange.property)) return "";
          return `Deleted: ${newChange.property} with value ${newChange.value}`;
        case "UPDATE":
          return typeof newChange.value === "object"
            ? `Updated: replaced value ${newChange.value.oldValue} of ${newChange.property} with value ${newChange.value.newValue}`
            : "";
      }
    })
    .filter((change) => change.trim() !== "")
    .join("\n");
}
