import {
  Alert,
  Autocomplete,
  Badge,
  Box,
  CircularProgress,
  Paper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { debounce } from "@mui/material/utils";
import getClassName from "classnames";
import { escapeRegExp, fromPairs, isArray, join, set, split, uniqBy, unset } from "lodash-es";
import * as React from "react";
import { useHistory, useLocation } from "react-router";
import { getHierarchy, Item } from "#components/Tree/index.tsx";
import { getLocalName } from "#containers/Schema/utils.ts";
import useApplyPrefixes from "#helpers/hooks/useApplyPrefixes.ts";
import useCurrentResource from "#helpers/hooks/useCurrentResource.ts";
import useCurrentSearch from "#helpers/hooks/useCurrentSearch.ts";
import useHideFooter from "#helpers/hooks/useHideFooter.ts";
import useSparql from "#helpers/hooks/useSparql.ts";
import { FontAwesomeIcon, Tree } from "../../../components";
import useConstructUrlToApi from "../../../helpers/hooks/useConstructUrlToApi";
import { useDatasetPrefixes } from "../../../helpers/hooks/useDatasetPrefixes";
import { parseSearchString, stringifyQuery } from "../../../helpers/utils";
import { useCurrentDataset } from "../../../reducers/datasetManagement";
import * as styles from "./style.scss";

interface ConceptSchemeData {
  conceptScheme: string;
  conceptSchemeLabel: string;
}

const SubclassTree: React.FC<{ className?: string }> = ({ className }) => {
  const history = useHistory();
  const currentResource = useCurrentResource();
  const search = useCurrentSearch();
  const conceptSchemeQueryString = search.conceptScheme as string;
  const conceptSchemeFilter = conceptSchemeQueryString
    ? join(
        split(conceptSchemeQueryString, ",").map((val) => `<${val}>`),
        ",",
      )
    : undefined;
  const [openFilterOptions, setOpenFilterOptions] = React.useState(conceptSchemeQueryString ? true : false);
  const prefixes = useDatasetPrefixes();
  useHideFooter();

  const conceptLimit = 1000;

  const { data, error, loading } = useSparql<Item[]>(`
prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
prefix skos: <http://www.w3.org/2004/02/skos/core#>
prefix skosxl: <http://www.w3.org/2008/05/skos-xl#>
prefix owl: <http://www.w3.org/2002/07/owl#>
select ?id ?parent (sample(?l) as ?label) (sample(?_conceptScheme) as ?conceptScheme) (sample(?_conceptSchemeLabel) as ?conceptSchemeLabel) where {
  {
    {
      select distinct ?concept where {
        {
          ?concept a/rdfs:subClassOf* skos:Concept
        }  union {
          ?concept skos:broader|^skos:broader|skos:narrower|^skos:narrower|skos:broadMatch|^skos:broadMatch|skos:narrowMatch|^skos:narrowMatch []
        }
        filter (!contains(str(?concept), ".well-known/genid"))
        ${conceptSchemeFilter ? `?concept skos:inScheme ?_conceptScheme .filter (?_conceptScheme in (${conceptSchemeFilter}))` : ""}

      }
      limit ${conceptLimit}
    }
    optional {
      ?concept skos:broader|^skos:narrower|skos:broadMatch|^skos:narrowMatch ?superclass
      optional {
        ?superclass skos:inScheme ?superclassScheme
      }
    }
    optional {
      ?concept rdfs:label|skos:prefLabel|skosxl:prefLabel/skosxl:literalForm ?l
    }
    optional {
      ?concept skos:inScheme ?_conceptScheme
      optional {
        ?_conceptScheme rdfs:label|skos:prefLabel ?_conceptSchemeLabel
      }
    }
   ${conceptSchemeFilter ? `bind(if(bound(?superclassScheme) && ?superclassScheme in (${conceptSchemeFilter}), ?superclass, <tree:root>) as ?parent)` : "bind(coalesce(?superclass, <tree:root>) as ?parent)"}
    bind(?concept as ?id)
  }
}
group by ?id ?parent
      `);

  const conceptSchemeSparql = useSparql<ConceptSchemeData[]>(`
        prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
        prefix skos: <http://www.w3.org/2004/02/skos/core#>

        select ?conceptScheme (sample(?_conceptSchemeLabel) as ?conceptSchemeLabel) where {
          ?concept skos:inScheme ?conceptScheme
          optional {
                ?conceptScheme rdfs:label|skos:prefLabel ?_conceptSchemeLabel
          }
        }
        group by ?conceptScheme
        limit ${conceptLimit}
              `);

  const [expandedKeys, setExpandedKeys] = React.useState<{ [key: string]: boolean }>({});

  React.useEffect(() => {
    if (data) {
      const { hierarchy } = getHierarchy(data, currentResource || "", prefixes);
      const toExpand = fromPairs(
        hierarchy
          ?.descendants()
          .filter((node) => node.data.iri === currentResource || "")
          .flatMap(
            (node) =>
              node
                .ancestors()
                .slice(1)
                .map((node) => [node.data.key, true]) || [],
          ),
      );
      setExpandedKeys((expandedKeys) => {
        return {
          ...expandedKeys,
          ...toExpand,
        };
      });

      const isHierarchyTreeNotEmpty = hierarchy && hierarchy.children && hierarchy.children.length > 0;

      if (!currentResource && isHierarchyTreeNotEmpty) {
        set(search, "resource", hierarchy.children![0].data.iri);
        history.replace({
          search: stringifyQuery(search),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentResource, data]);

  return (
    <Paper className={getClassName(className, "p-3")}>
      <Box className={styles.hierarchyContainer}>
        <div className="mb-5 flex center g-5">
          <div className="flex column grow">
            <div className="flex">
              {data && data?.length >= conceptLimit ? <SearchField /> : <SelectField data={data} />}
              <Badge
                badgeContent={conceptSchemeQueryString ? split(conceptSchemeQueryString, ",").length : undefined}
                color="primary"
                overlap="circular"
              >
                <ToggleButtonGroup value={openFilterOptions ? "filter" : ""}>
                  <ToggleButton
                    aria-label="Show filter options"
                    onClick={() => setOpenFilterOptions(!openFilterOptions)}
                    className={styles.filterButton}
                    value={"filter"}
                  >
                    <FontAwesomeIcon icon="filter" size="xl" />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Badge>
            </div>
            {openFilterOptions && (
              <div className={styles.filters}>
                <Filters
                  data={conceptSchemeSparql.data}
                  error={conceptSchemeSparql.error}
                  loading={conceptSchemeSparql.loading}
                />
              </div>
            )}
          </div>
        </div>
        {error && <Alert severity="error">An error occurred. Could not fetch SKOS hierarchy.</Alert>}
        {!error && data?.length === 0 && <Alert severity="info">No SKOS hierarchy available.</Alert>}
        {loading && <CircularProgress color="primary" className={getClassName(styles.treeLoading, "my-2 flex grow")} />}
        {!error && (
          <Tree
            className={styles.tree}
            items={loading || !data ? [] : data}
            currentItemIri={currentResource}
            expandedKeys={expandedKeys}
            onToggle={(e) => setExpandedKeys(e.value)}
            emptyMessage=" "
            onSelect={({ node }) => {
              let search = { resource: node.data.iri };
              if (conceptSchemeQueryString) set(search, "conceptScheme", conceptSchemeQueryString);
              history.push({
                search: stringifyQuery(search),
              });
            }}
          />
        )}
      </Box>
    </Paper>
  );
};

const SelectField: React.FC<{ data: Item[] | undefined }> = ({ data }) => {
  const history = useHistory();
  const applyPrefixes = useApplyPrefixes();
  const conceptSchemeQueryString = parseSearchString(useLocation().search).conceptScheme as string;
  return (
    <Autocomplete
      getOptionLabel={(option) => (typeof option === "string" ? option : option.label || applyPrefixes(option.id))}
      getOptionKey={(option) => option.id}
      options={uniqBy(data || [], "id")}
      autoComplete
      disabled={(data || []).length === 0}
      filterSelectedOptions
      fullWidth
      noOptionsText="No results"
      onChange={(_event: any, newValue: Item | null, reason) => {
        if (reason === "selectOption") {
          let search = { resource: newValue?.id };
          if (conceptSchemeQueryString) set(search, "conceptScheme", conceptSchemeQueryString);
          history.push({
            search: stringifyQuery(search),
          });
        }
      }}
      renderInput={(params: any) => {
        return <TextField variant="outlined" placeholder="Search in hierarchy" {...params} />;
      }}
    />
  );
};

interface Concept {
  id: string;
  label: string;
}
const SearchField: React.FC<{}> = () => {
  const [value, setValue] = React.useState<Concept | null>(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState<readonly Concept[]>([]);

  const currentDs = useCurrentDataset()!;
  const sparqlUrl = useConstructUrlToApi()({
    pathname: `/_console/sparql`,
    fromBrowser: true,
  });

  const sparql = React.useCallback(
    async (query: string, abortSignal: AbortSignal) => {
      const response = await fetch(sparqlUrl, {
        credentials: "same-origin",
        signal: abortSignal,
        method: "POST",
        headers: { Accept: "application/json" },
        body: new URLSearchParams({
          account: currentDs.owner.accountName,
          dataset: currentDs.name,
          queryString: query,
        }),
      });
      if (!response.ok) throw new Error(response.statusText);
      const result = await response.json();
      return result;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sparqlUrl, currentDs.owner.accountName, currentDs.name],
  );

  const debouncedQuery = React.useMemo(
    () =>
      debounce(
        (
          { searchTerm, abortSignal }: { searchTerm: string; abortSignal: AbortSignal },
          callback: (results?: readonly Concept[]) => void,
        ) => {
          searchTerm = escapeRegExp(searchTerm.replace(/"/g, "")).replace(/\\/g, "\\\\");
          sparql(
            `
            prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
            prefix skos: <http://www.w3.org/2004/02/skos/core#>
            prefix skosxl: <http://www.w3.org/2008/05/skos-xl#>
            prefix owl: <http://www.w3.org/2002/07/owl#>

            select ?id ?label where {
              ?id a/rdfs:subClassOf* skos:Concept .

              filter (!regex(str(?id), "\.well-known/genid"))

              ?id rdfs:label|skos:prefLabel|skosxl:prefLabel/skosxl:literalForm ?label .

              filter (regex(?label, "${searchTerm}", "i"))

            }
            order by
              desc(regex(?label, "^${searchTerm}", "i"))
              asc(strlen(?label))
            limit 20
            `,
            abortSignal,
          )
            .then((results) => {
              callback(results);
            })
            .catch(() => {});
        },
        400,
      ),
    [sparql],
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    let active = true;

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    debouncedQuery({ searchTerm: inputValue, abortSignal: abortController.signal }, (results?: readonly Concept[]) => {
      if (active) {
        let newOptions: readonly Concept[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
      abortController.abort("Not needed anymore");
    };
  }, [value, inputValue, debouncedQuery]);

  const history = useHistory();

  return (
    <Autocomplete
      getOptionLabel={(option) => (typeof option === "string" ? option : option.label)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      fullWidth
      noOptionsText="No results"
      onChange={(_event: any, newValue: Concept | null, reason) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        if (reason === "selectOption") {
          history.push({
            search: stringifyQuery({ resource: newValue?.id }),
          });
        }
      }}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params: any) => {
        return <TextField variant="outlined" placeholder="Search in hierarchy" {...params} />;
      }}
    />
  );
};

const Filters: React.FC<{ data: ConceptSchemeData[] | undefined; error: any; loading: boolean }> = ({
  data,
  error,
  loading,
}) => {
  const conceptSchemeQueryString = parseSearchString(useLocation().search).conceptScheme as string;
  const history = useHistory();
  const search = useCurrentSearch();

  if (loading) {
    return (
      <div className="flex horizontalCenter">
        Loading filter...
        <CircularProgress color="primary" size={20} className="ml-2" />
      </div>
    );
  }

  if (!data || error) return null;

  return (
    <>
      Filter
      <Autocomplete
        value={data.filter((_data) => split(conceptSchemeQueryString, ",").includes(_data.conceptScheme)) || []}
        className={styles.filter}
        options={data || []}
        getOptionLabel={(option) => option.conceptSchemeLabel || getLocalName(option.conceptScheme as string)}
        getOptionKey={(option) => option.conceptScheme}
        autoComplete
        filterSelectedOptions
        multiple
        disabled={(data || []).length === 0}
        onChange={(_event: any, newValues: ConceptSchemeData[] | null, reason) => {
          if (reason === "selectOption" || reason === "clear" || reason === "removeOption") {
            if (newValues && isArray(newValues) && newValues.length > 0) {
              const queryValues = join(
                newValues.map((val) => val.conceptScheme),
                ",",
              );
              set(search, "conceptScheme", queryValues);
            } else {
              unset(search, "conceptScheme");
            }
            history.push({
              search: stringifyQuery(search),
            });
          }
        }}
        renderInput={(params: any) => {
          return <TextField variant="outlined" placeholder="Concept scheme" {...params} />;
        }}
      />
    </>
  );
};

export default React.memo(SubclassTree);
