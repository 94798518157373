import { Alert, Skeleton } from "@mui/material";
import * as React from "react";
import { factories } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize.js";
import useCurrentResource from "#helpers/hooks/useCurrentResource.ts";
import useSparql from "#helpers/hooks/useSparql.ts";

const factory = factories.compliant;
const Description: React.FC<{}> = ({}) => {
  const currentClass = useCurrentResource();

  const { data, error, loading } = useSparql<[{ d?: string }?]>(
    currentClass &&
      `
prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
prefix foaf: <http://xmlns.com/foaf/0.1/>
prefix schema: <https://schema.org/>
prefix dc: <http://purl.org/dc/elements/1.1/>
prefix dct: <http://purl.org/dc/terms/>
prefix sh: <http://www.w3.org/ns/shacl#>
prefix skos: <http://www.w3.org/2004/02/skos/core#>

select ?d where {
  bind(${termToString(factory.namedNode(currentClass))} as ?currentClass)
  optional {
    ?currentClass dct:description ?d
    filter(lang(?d) = "en")
  }
  optional {
    ?currentClass dc:description ?d
    filter(lang(?d) = "en")
  }
  optional {
    ?currentClass schema:description ?d
    filter(lang(?d) = "en")
  }
  optional {
    ?currentClass rdfs:comment ?d
    filter(lang(?d) = "en")
  }
  optional {
    ?currentClass sh:description ?d
    filter(lang(?d) = "en")
  }
  optional {
    ?currentClass dct:description ?d
  }
  optional {
    ?currentClass dc:description ?d
  }
  optional {
    ?currentClass schema:description ?d
  }
  optional {
    ?currentClass rdfs:comment ?d
  }
  optional {
    ?currentClass sh:description ?d
  }
  optional {
    ?currentClass skos:definition ?d
  }
} limit 1
    `,
  );

  if (!currentClass) return null;

  if (error) return <Alert severity="warning">Description could not be loaded.</Alert>;

  if (loading) return <Skeleton />;

  if (!data || !data[0] || !data[0].d) return null;

  return <div>{data[0].d}</div>;
};

export default Description;
