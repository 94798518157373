import { DatasetPublic } from "@triply/utils/Models.js";
import { factories } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize.js";
import { SparqlBasedConstraint } from "#containers/DataModel/ClassInfo.tsx";

const factory = factories.compliant;

export const getSparqlBasedConstrains = async (
  classIri: string,
  sparqlUrl: string,
  dataset: DatasetPublic,
): Promise<SparqlBasedConstraint[]> => {
  const constraintsQuery = `
      prefix owl: <http://www.w3.org/2002/07/owl#>
      prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
      prefix sh: <http://www.w3.org/ns/shacl#>
  
      select
        ?nodeShape
        ?inherited
        ?query
        ?shSparql
        ?message
      where {
        bind(${termToString(factory.namedNode(classIri))} as ?currentClass)
        ?currentNodeShape sh:targetClass ?currentClass.
        ?currentClass rdfs:subClassOf*/^sh:targetClass ?nodeShape.
  
        bind((?nodeShape != ?currentNodeShape) as ?inherited)
  
        ?nodeShape sh:sparql ?shSparql .
        ?shSparql sh:select ?query .
  
        optional {
          ?shSparql sh:message ?message
        }
  
      }
  
      group by
      ?currentClass
      ?nodeShape
      ?propertyShape
      ?inherited
      ?class
      ?id
      ?query
      ?message
      ?shSparql
  
      order by
      ?message
    `;
  const constraintsResponse = await fetch(sparqlUrl, {
    credentials: "same-origin",
    method: "POST",
    headers: { Accept: "application/json" },
    body: new URLSearchParams({
      account: dataset.owner.accountName,
      dataset: dataset.name,
      queryString: constraintsQuery,
    }),
  });

  return constraintsResponse.json();
};
